import { observer } from "mobx-react-lite";
import * as React from "react";
import { BalanceItem } from "@/pages/HomePage/components/Balances/Item";
import { Box } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
export var Balances = observer(function () {
    var accounts = useStoreContext(AccountListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var balanceList = useStore(BalanceListStore, currencyList);
    var account = accounts.checking;
    React.useEffect(function () {
        if (account) {
            balanceList.sync(account.account);
        }
    }, [balanceList, currencyList.list.length, accounts.list.length]);
    var findCurrencyBalance = function (currency) {
        var _a, _b;
        return ((_b = (currency.slug && ((_a = balanceList.data[currency.slug]) === null || _a === void 0 ? void 0 : _a.balance))) !== null && _b !== void 0 ? _b : "0");
    };
    var loaded = accounts.crypto.every(function (item) { var _a; return (_a = balanceList.data[item.account]) === null || _a === void 0 ? void 0 : _a.balance; });
    if (accounts.list.length) {
        return (React.createElement(Box, { display: "flex", flexDirection: "column", bgColor: "general-white", borderRadius: "22px", padding: "32px", marginTop: "16px", marginBottom: "40px" },
            React.createElement(Box, { position: "relative" },
                React.createElement(Box, { display: "flex", gap: "20px", flexDirection: "column" }, currencyList.withWalletEnabled
                    .filter(function (currency) { return currency.type === "CRYPTO"; })
                    .map(function (currency) {
                    var _a;
                    var balanceAmount = findCurrencyBalance(currency);
                    return (React.createElement(BalanceItem, { key: currency.id, currency: currency.shortName, slug: currency.slug, id: accounts.checking.account, amount: balanceAmount, description: currency.description, type: (_a = currency.type) === null || _a === void 0 ? void 0 : _a.toLowerCase(), iconUrl: currency.iconUrl }));
                })),
                !loaded && (React.createElement(Loader, { position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", size: 24 })))));
    }
    return null;
});
