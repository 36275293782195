var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, runInAction } from "mobx";
import { cardHolderApi } from "@/api/cardHolder";
import { CardType } from "@/api/cardHolder/types";
import { acquiringApi } from "@/api/acquiring";
import { InvoicePaymentProviderType } from "@/api/acquiring/types";
var CardOrderStore = /** @class */ (function () {
    function CardOrderStore() {
        this.currentStep = 1;
        this.isVerified = false;
        this.cardDesignId = "";
        this.firstName = "";
        this.lastName = "";
        this.cardholderName = "";
        this.success = false;
        this.loading = false;
        this.selectedCurrency = null;
        this.selectedBlockchain = null;
        this.enoughFunds = false;
        this.convertedAmount = "";
        this.promoCode = "";
        this.promoCodeApplied = false;
        this.isPromoCodeValid = false;
        this.invoiceId = undefined;
        this.billingAddress = {
            country: "",
            city: "",
            state: "",
            postalCode: "",
            addressLine1: "",
            addressLine2: "",
        };
        this.steps = [
            { step: 1, label: "Pick a card", active: true },
            { step: 2, label: "Identity verification" },
            { step: 3, label: "Billing Address" },
            { step: 4, label: "Cardholder name" },
            { step: 5, label: "Payment" },
        ];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    Object.defineProperty(CardOrderStore.prototype, "maxStep", {
        get: function () {
            return this.isVerified ? this.steps.length : this.steps.length - 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CardOrderStore.prototype, "availableSteps", {
        get: function () {
            return this.isVerified
                ? this.steps.filter(function (step) { return step.step !== 2; })
                : this.steps;
        },
        enumerable: false,
        configurable: true
    });
    CardOrderStore.prototype.canGoToNextStep = function () {
        return this.currentStep < this.maxStep;
    };
    CardOrderStore.prototype.canGoToPreviousStep = function () {
        return this.currentStep > 1;
    };
    CardOrderStore.prototype.nextStep = function () {
        if (this.canGoToNextStep()) {
            this.currentStep = this.getNextStep();
        }
    };
    CardOrderStore.prototype.previousStep = function () {
        if (this.canGoToPreviousStep()) {
            this.currentStep = this.getPreviousStep();
        }
    };
    CardOrderStore.prototype.getNextStep = function () {
        var _this = this;
        var _a;
        var currentIndex = this.availableSteps.findIndex(function (step) { return step.step === _this.currentStep; });
        return ((_a = this.availableSteps[currentIndex + 1]) === null || _a === void 0 ? void 0 : _a.step) || this.currentStep;
    };
    CardOrderStore.prototype.getPreviousStep = function () {
        var _this = this;
        var _a;
        var currentIndex = this.availableSteps.findIndex(function (step) { return step.step === _this.currentStep; });
        return ((_a = this.availableSteps[currentIndex - 1]) === null || _a === void 0 ? void 0 : _a.step) || this.currentStep;
    };
    CardOrderStore.prototype.setVerificationStatus = function (isVerified) {
        this.isVerified = isVerified;
    };
    CardOrderStore.prototype.setCardDesignId = function (cardDesignId) {
        this.cardDesignId = cardDesignId;
    };
    CardOrderStore.prototype.setBillingAddress = function (field, value) {
        this.billingAddress[field] = value;
    };
    CardOrderStore.prototype.toggleSuccess = function () {
        this.success = !this.success;
    };
    CardOrderStore.prototype.createCardRequest = function (cardOfferId) {
        return __awaiter(this, void 0, void 0, function () {
            var cardRequest, response, invoiceId_1, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        cardRequest = {
                            card_type: CardType.VIRTUAL,
                            provider: "REAP",
                            pii: {
                                type: "UserPII",
                                firstName: "First Name",
                                lastName: "Last Name",
                                phone: "+381623334455",
                                address: {
                                    // @ts-ignore
                                    id: null,
                                    town: this.billingAddress.city,
                                    state: this.billingAddress.state,
                                    street: this.billingAddress.addressLine1,
                                    country: this.billingAddress.country,
                                    postCode: this.billingAddress.postalCode,
                                    confirmed: true,
                                    subStreet: this.billingAddress.addressLine2,
                                    flatNumber: "1",
                                    buildingName: "Buckingham Palace",
                                    buildingNumber: "10",
                                },
                                dob: "1990-01-20",
                                documentType: "Passport",
                                documentNumber: "11223344",
                            },
                            delivery_address: {
                                country: this.billingAddress.country,
                                postCode: this.billingAddress.postalCode,
                                state: this.billingAddress.state,
                                town: this.billingAddress.city,
                                street: this.billingAddress.addressLine1,
                                subStreet: this.billingAddress.addressLine2,
                            },
                            card_offer_id: cardOfferId,
                            card_design_id: this.cardDesignId,
                        };
                        return [4 /*yield*/, cardHolderApi.createCardRequest1(cardRequest)];
                    case 1:
                        response = _a.sent();
                        invoiceId_1 = response.data.invoiceId;
                        if (invoiceId_1) {
                            runInAction(function () {
                                _this.invoiceId = invoiceId_1;
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error("Failed to create card request:", error_1);
                        throw error_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CardOrderStore.prototype.pay = function (accountId, discountCode) {
        return __awaiter(this, void 0, void 0, function () {
            var success, createPaymentResponse, paymentId, paymentConfirmResponse, e_1;
            var _this = this;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        success = this.success;
                        runInAction(function () {
                            _this.loading = true;
                        });
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 7, , 8]);
                        if (!(accountId &&
                            this.invoiceId &&
                            ((_a = this.selectedCurrency) === null || _a === void 0 ? void 0 : _a.shortName))) return [3 /*break*/, 6];
                        if (!discountCode) return [3 /*break*/, 3];
                        return [4 /*yield*/, acquiringApi.invoice.applyDiscount(this.invoiceId, {
                                discountCode: discountCode,
                            })];
                    case 2:
                        _d.sent();
                        _d.label = 3;
                    case 3: return [4 /*yield*/, acquiringApi.invoice.paymentGetOrCreate({
                            accountId: accountId,
                            invoiceId: this.invoiceId,
                            currency: (_b = this.selectedCurrency) === null || _b === void 0 ? void 0 : _b.slug,
                            type: InvoicePaymentProviderType.PLATFORM,
                        })];
                    case 4:
                        createPaymentResponse = _d.sent();
                        paymentId = createPaymentResponse.data.id;
                        if (!paymentId) return [3 /*break*/, 6];
                        return [4 /*yield*/, acquiringApi.invoice.pay({
                                paymentId: paymentId,
                                accountId: accountId,
                                invoiceId: this.invoiceId,
                                currency: (_c = this.selectedCurrency) === null || _c === void 0 ? void 0 : _c.slug,
                                type: InvoicePaymentProviderType.PLATFORM,
                            })];
                    case 5:
                        paymentConfirmResponse = _d.sent();
                        if (paymentConfirmResponse.data) {
                            success = true;
                        }
                        _d.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        e_1 = _d.sent();
                        console.error(e_1);
                        return [3 /*break*/, 8];
                    case 8:
                        runInAction(function () {
                            _this.success = success;
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(CardOrderStore.prototype, "availableBlockchains", {
        get: function () {
            var _a, _b;
            return (((_b = (_a = this.selectedCurrency) === null || _a === void 0 ? void 0 : _a.tokens) === null || _b === void 0 ? void 0 : _b.map(function (token) { return token.blockchain; }).filter(function (blockchain) {
                return blockchain !== undefined;
            })) || []);
        },
        enumerable: false,
        configurable: true
    });
    return CardOrderStore;
}());
export { CardOrderStore };
