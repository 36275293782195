import { observer } from "mobx-react-lite";
import * as React from "react";
import { User } from "@/pages/UserPage/components/User";
export var Header = observer(function () {
    // const media = useMedia();
    // const menu = useStoreContext(MenuStore);
    // const verification = useGetVerificationInfo();
    // const companyInfo = useGetCompanyInfo();
    //
    return (React.createElement(User, null)
    // <Box
    //     height={72}
    //     display="flex"
    //     alignItems="center"
    //     justifyContent={"space-between"}
    //     marginBottom="20px"
    //     gap="22px"
    // >
    //     {media.phone && (
    //         <Link to={generatePath(ROUTES.root)}>
    //             <BaseIcon icon="LogoSmall" size={36} />
    //         </Link>
    //     )}
    //     {companyInfo.data?.companyName ? (
    //         <Box display="flex" gap="10px" alignItems="center">
    //             <Box
    //                 display="flex"
    //                 alignItems={media.phone ? "flex-start" : "center"}
    //                 gap={media.phone ? "0" : "10px"}
    //                 flexDirection={media.phone ? "column" : "row"}
    //             >
    //                 {verification.data?.status ===
    //                 VerificationStatus.GREEN ? (
    //                     <Badge color="green">Completed</Badge>
    //                 ) : verification.data?.status ===
    //                   VerificationStatus.REJECT ? (
    //                     <Badge color="red">Reject</Badge>
    //                 ) : (
    //                     <Badge color="white">In progress</Badge>
    //                 )}
    //             </Box>
    //         </Box>
    //     ) : (
    //         <Box />
    //     )}
    //     {!media.phone && (
    //         <Box display="flex" alignItems="center" gap="22px">
    //             {/*<Currency />*/}
    //             <User />
    //         </Box>
    //     )}
    //     {media.phone && (
    //         <BaseIcon icon="Burger" size={24} onClick={menu.toggle} />
    //     )}
    // </Box>
    );
});
