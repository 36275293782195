import AlertClose from "./alert-close.svg";
import Arrow from "./arrow.svg";
import AppleWallet from "./apple-wallet.svg";
import Approved from "./approved.svg";
import InProgress from "./in-progress.svg";
import Rejected from "./rejected.svg";
import Back from "./back.svg";
import Bank from "./bank.svg";
import Burger from "./burger.svg";
import Card from "./card.svg";
import Check from "./check.svg";
import Checked from "./checked.svg";
import Close from "./close.svg";
import CloseLight from "./close-light.svg";
import Copy from "./copy.svg";
import Cloud from "./cloud.svg";
import ClosedEye from "./closed-eye.svg";
import Cross from "./cross.svg";
import Clock from "./clock.svg";
import Crypto from "./crypto.svg";
import Deposit from "./deposit.svg";
import Doc from "./doc.svg";
import Download from "./download.svg";
import DropDown from "./drop-down.svg";
import Exchange from "./exchange.svg";
import Eye from "./eye.svg";
import Failed from "./failed.svg";
import Freeze from "./freeze.svg";
import Google from "./google.svg";
import Help from "./help.svg";
import Info from "./info.svg";
import Lock from "./lock.svg";
import LogoSmall from "./logo-small.svg";
import Logo from "./logo.svg";
import MenuCompany from "./menu-company.svg";
import MenuDashboard from "./menu-dashboard.svg";
import MenuTransactions from "./menu-transactions.svg";
import More from "./more.svg";
import Navigation from "./navigation.svg";
import PaymentTypeCard from "./payment-type-card.svg";
import PaymentTypeCrypto from "./payment-type-crypto.svg";
import PaymentTypeOther from "./payment-type-other.svg";
import Pending from "./pending.svg";
import Plus from "./plus.svg";
import Qr from "./qr.svg";
import Radio from "./radio.svg";
import ReapBlack from "./reap/reap-black.svg";
import Recipient from "./recipient.svg";
import Right from "./right.svg";
import Search from "./search.svg";
import Spinner from "./spinner.svg";
import Success from "./success.svg";
import Swap from "./swap.svg";
import Transfer from "./transfer.svg";
import UnChecked from "./unchecked.svg";
import Worldwide from "./worldwide.svg";
import Usd from "./usd.svg";
import Apple from "./apple.svg";
import CardFlip from "./card-flip.svg";
import Visa from "./visa.svg";
import PlusSmall from "./plus-small.svg";
import Block from "./block.svg";
import Limits from "./limits.svg";
import Settings from "./settings.svg";
import Point from "./point.svg";
import Clear from "./clear.svg";
import Warning from "./warning.svg";
import WarningOrange from "./warning-orange.svg";
import Star from "./star.svg";
import Dollar from "./dollar.svg";
import Nfc from "./nfc.svg";
import VisaMastercard from "./visa-mastercard.svg";
export var Icons = {
    Arrow: Arrow,
    Apple: Apple,
    AppleWallet: AppleWallet,
    Approved: Approved,
    Block: Block,
    InProgress: InProgress,
    Rejected: Rejected,
    Eye: Eye,
    Clear: Clear,
    Clock: Clock,
    Copy: Copy,
    ClosedEye: ClosedEye,
    Back: Back,
    Logo: Logo,
    CloseLight: CloseLight,
    UnChecked: UnChecked,
    Checked: Checked,
    AlertClose: AlertClose,
    DropDown: DropDown,
    Card: Card,
    CardFlip: CardFlip,
    Close: Close,
    Cloud: Cloud,
    Cross: Cross,
    Doc: Doc,
    Pending: Pending,
    Failed: Failed,
    Freeze: Freeze,
    LogoSmall: LogoSmall,
    MenuCompany: MenuCompany,
    MenuDashboard: MenuDashboard,
    MenuTransactions: MenuTransactions,
    Help: Help,
    Burger: Burger,
    Lock: Lock,
    Limits: Limits,
    Navigation: Navigation,
    Deposit: Deposit,
    Transfer: Transfer,
    Exchange: Exchange,
    Info: Info,
    Plus: Plus,
    Spinner: Spinner,
    Right: Right,
    Radio: Radio,
    PaymentTypeCard: PaymentTypeCard,
    PaymentTypeCrypto: PaymentTypeCrypto,
    PaymentTypeOther: PaymentTypeOther,
    Download: Download,
    Success: Success,
    Search: Search,
    Qr: Qr,
    Swap: Swap,
    Crypto: Crypto,
    Recipient: Recipient,
    Bank: Bank,
    Check: Check,
    More: More,
    ReapBlack: ReapBlack,
    Worldwide: Worldwide,
    Usd: Usd,
    Visa: Visa,
    PlusSmall: PlusSmall,
    Settings: Settings,
    Google: Google,
    Point: Point,
    Warning: Warning,
    WarningOrange: WarningOrange,
    Star: Star,
    Dollar: Dollar,
    Nfc: Nfc,
    VisaMastercard: VisaMastercard,
};
