import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { useNavigate } from "react-router-dom";
import { generateQueryPath } from "@/shared/utils/path";
import { ExchangeType, ROUTES } from "@/routes";
export var CardActionButton = function (_a) {
    var icon = _a.icon, label = _a.label, cardId = _a.cardId;
    var navigate = useNavigate();
    var handleClick = function () {
        switch (label) {
            case "Add money":
                navigate(generateQueryPath(ROUTES.exchange, {
                    type: ExchangeType.Sell,
                }, {
                    from: "usdt",
                    to: "usdt",
                    toCardId: cardId,
                }));
                break;
            default:
        }
    };
    return (React.createElement(Box, { display: "flex", alignItems: "center", gap: "4px", flexDirection: "column", width: "100px", onClick: handleClick, sx: {
            cursor: "pointer",
        } },
        React.createElement(Box, { width: 44, height: 44, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "16px", bgColor: "deep-blue-500" },
            React.createElement(BaseIcon, { icon: icon, size: 24, color: "black-50" })),
        React.createElement(Box, null,
            React.createElement(Typography, { color: "black-1000", type: "text16" }, label))));
};
