import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { Balances } from "@/pages/HomePage/components/Balances";
import { TotalBalance } from "@/pages/HomePage/components/TotalBalance";
import { Box, Button, Typography } from "@/shared/components";
import { Cards } from "@/pages/HomePage/components/Cards";
import { UpgradeToProBanner } from "@/shared/components/Banners/UpgradeToProBanner";
import { useToggler } from "@/shared/hooks/useToggler";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
import { FinalizeSubscriptionBanner } from "@/shared/components/Banners/FinalizeSubscriptionBanner";
import { ROUTES } from "@/routes";
import { VerificationLayout } from "@/shared/components/VerificationLayout";
export var HomePage = observer(function () {
    var data = useGetVerificationInfo().data;
    var subscriptions = useStoreContext(SubscriptionStore);
    var status = data === null || data === void 0 ? void 0 : data.status;
    var upgradeBanner = useToggler(true);
    var finishSubscriptionBanner = useToggler(true);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
        React.createElement(Typography, { type: "text28Bold" }, "Dashboard"),
        status === VerificationStatus.GREEN ? (React.createElement(React.Fragment, null,
            React.createElement(TotalBalance, null),
            !subscriptions.hasInitSubscription &&
                !subscriptions.hasActiveSubscription &&
                upgradeBanner.enabled && (React.createElement(UpgradeToProBanner, { onClose: upgradeBanner.off })),
            subscriptions.hasInitSubscription &&
                !subscriptions.hasActiveSubscription &&
                finishSubscriptionBanner.enabled && (React.createElement(FinalizeSubscriptionBanner, { onClose: finishSubscriptionBanner.off })),
            React.createElement(Box, null,
                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Typography, { type: "text24" }, "Cards"),
                    React.createElement(Button, { variant: "Transparent", LinkComponent: Link, to: generatePath(ROUTES.walletList) }, "See all")),
                React.createElement(Cards, null)),
            React.createElement(Box, null,
                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                    React.createElement(Typography, { type: "text24" }, "Crypto"),
                    React.createElement(Button, { variant: "Transparent", LinkComponent: Link, to: generatePath(ROUTES.walletList) }, "See all")),
                React.createElement(Balances, null)))) : (React.createElement(VerificationLayout, { withTotalBalance: true }))));
});
var StyledOverlay = styled(Box)(function () { return ({
    background: "linear-gradient(180deg, rgba(246, 247, 248, 0.4) 0%, #F6F7F8 100%)",
}); });
