import { formattedTokenAmount } from "@broxus/js-utils";
import BigNumber from "bignumber.js";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box, Typography } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { BalanceType } from "@/shared/stores/BalanceTypeStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { UserCurrency, UserCurrencyStore, } from "@/shared/stores/UserCurrencyStore";
export var TotalBalance = observer(function (_a) {
    var _b = _a.type, type = _b === void 0 ? BalanceType.All : _b;
    var accountList = useStoreContext(AccountListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var balanceList = useStore(BalanceListStore, currencyList);
    var userCurrency = useStoreContext(UserCurrencyStore);
    var account = accountList.checking;
    React.useEffect(function () {
        if (account) {
            balanceList.sync(account.account);
        }
    }, [balanceList, accountList.list.length, currencyList.list.length]);
    var getBalance = function (currencies) {
        if (!userCurrency.selected) {
            return undefined;
        }
        var currencyNamesFiltered = currencies
            .filter(function (currency) { return currency.eurPrice && currency.slug; })
            .map(function (currency) { return currency.slug; });
        var balances = currencyNamesFiltered.map(function (currency) { return balanceList.data[currency]; });
        var total = "0";
        // eslint-disable-next-line no-plusplus
        for (var i = 0; i < balances.length; i++) {
            var item = balances[i];
            if (item) {
                var fromRate = currencyList.rates[item.currency.toLowerCase()];
                var toRate = currencyList.rates[userCurrency.selected];
                if ((fromRate === null || fromRate === void 0 ? void 0 : fromRate.eurPrice) && (toRate === null || toRate === void 0 ? void 0 : toRate.eurPrice)) {
                    total = new BigNumber(total)
                        .plus(new BigNumber(fromRate.eurPrice)
                        .multipliedBy(item.balance)
                        .dividedBy(toRate.eurPrice)
                        .toFixed())
                        .toFixed();
                }
            }
        }
        return total;
    };
    var balance = type === BalanceType.All
        ? getBalance(currencyList.list)
        : type === BalanceType.Crypto
            ? getBalance(currencyList.crypto)
            : type === BalanceType.Fiat
                ? getBalance(currencyList.fiat)
                : undefined;
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" },
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px" },
            React.createElement(Typography, { type: "text16", color: "black-500" }, "Total balance"),
            React.createElement(Box, { display: "flex", alignItems: "center", gap: "15px" }, balance === undefined ? (React.createElement(Loader, { size: 24, containerSize: 47 })) : (React.createElement(Typography, { type: "text28" },
                userCurrency.selected === UserCurrency.Eur
                    ? "€"
                    : userCurrency.selected === UserCurrency.Usd
                        ? "$"
                        : null,
                formattedTokenAmount(balance)))))));
});
