import React from "react";
import { Popup } from "@/shared/components/Popup";
import { Box, Button, Typography } from "@/shared/components";
import Success from "@/shared/img/success.png";
export var OrderConfirmationPopup = function (_a) {
    var onClose = _a.onClose;
    return (React.createElement(Popup, null,
        React.createElement(Box, { padding: "32px", display: "flex", flexDirection: "column", alignItems: "center", gap: "32px" },
            React.createElement(Box, null,
                React.createElement("img", { src: Success, width: "160", height: "140", alt: "success-order" })),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                React.createElement(Typography, { type: "text28", textAlign: "center" }, "Congratulations!"),
                React.createElement(Typography, { textAlign: "center" }, "Your order was successfully completed. The\u00A0card will be with you soon.")),
            React.createElement(Button, { size: "l", onClick: onClose, width: "100%" }, "Great"))));
};
