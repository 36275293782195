import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, Typography } from "@/shared/components";
import { SubscriptionDescription } from "@/pages/SubscriptionPage/components/Card/SubscriptionDescription";
import { useStoreContext } from "@/shared/hooks/useStore";
import { DiscountStore } from "@/shared/stores/DiscountStore";
export var SubscriptionCard = observer(function (_a) {
    var subscription = _a.subscription, onSelect = _a.onSelect;
    var discount = useStoreContext(DiscountStore);
    var hasDiscount = discount.list && discount.list.length > 0;
    var discountedAmount = hasDiscount && discount.list[0].discount_percent
        ? (subscription.amount *
            (100 - discount.list[0].discount_percent)) /
            100
        : subscription.amount;
    return (React.createElement(Box, { padding: "36px 12px", borderRadius: "16px", bgColor: "general-white", display: "flex", flexDirection: "column", justifyContent: "space-between" },
        React.createElement(Box, null,
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", gap: "4px" },
                React.createElement(Typography, { sx: {
                        fontSize: "18px",
                        fontWeight: "bold",
                    }, color: "black-1200" }, subscription.name.toUpperCase()),
                hasDiscount ? (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                    React.createElement(Typography, { sx: {
                            textDecoration: "line-through",
                        }, color: "black-500" },
                        "$",
                        subscription.amount),
                    React.createElement(Typography, { color: "deep-blue-500" },
                        "$",
                        discountedAmount.toFixed(2)))) : (React.createElement(Typography, { sx: {
                        fontSize: "18px",
                        fontWeight: "bold",
                    }, color: "deep-blue-500" },
                    "$",
                    subscription.amount)),
                React.createElement(Typography, { color: "black-1200", type: "text14" }, "monthly")),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "4px", marginTop: "16px" },
                React.createElement(Typography, { textAlign: "center" },
                    React.createElement(SubscriptionDescription, { description: subscription.description })))),
        React.createElement(Box, { marginTop: "52px", display: "flex", flexDirection: "column" },
            React.createElement(Button, { size: "l", onClick: function () { return onSelect(subscription); } },
                "Get ",
                subscription.name.toUpperCase()))));
});
