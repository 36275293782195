var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { walletApi } from "@/api/wallet/api";
var BalanceListStore = /** @class */ (function () {
    function BalanceListStore(currencies) {
        var _this = this;
        this.currencies = currencies;
        this.data = {};
        this.loading = {};
        makeAutoObservable(this, {}, { autoBind: true });
        reaction(function () { return _this.currencies.list.length > 0; }, function (isLoaded) {
            if (isLoaded) {
                _this.sync();
            }
        });
    }
    BalanceListStore.prototype.sync = function () {
        var ids = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            ids[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var filtered, currencyNamesFiltered, newData_1, resp, balances_1, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filtered = ids.filter(function (id) { return !!id; });
                        if (!(filtered.length > 0 && this.currencies)) return [3 /*break*/, 5];
                        currencyNamesFiltered = this.currencies.list
                            .filter(function (currency) { return !!currency.slug; })
                            .map(function (currency) { return currency.slug; });
                        newData_1 = {};
                        this.loading = filtered.reduce(function (acc, item) {
                            var _a;
                            return (__assign(__assign({}, acc), (_a = {}, _a[item] = true, _a)));
                        }, __assign({}, this.loading));
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, walletApi.balance.getBalanceList({
                                accountIds: filtered,
                            }, {
                                paramsSerializer: {
                                    indexes: null,
                                },
                            })];
                    case 2:
                        resp = _a.sent();
                        balances_1 = resp.data;
                        newData_1 = currencyNamesFiltered.reduce(function (acc, currency) {
                            var _a;
                            var balanceForCurrency = balances_1.find(function (balance) {
                                return balance.currency.toLowerCase() === currency;
                            });
                            return __assign(__assign({}, acc), (_a = {}, _a[currency] = balanceForCurrency !== null && balanceForCurrency !== void 0 ? balanceForCurrency : {
                                currency: currency,
                                balance: "0",
                                account: filtered[0],
                            }, _a));
                        }, __assign({}, newData_1));
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        runInAction(function () {
                            _this.data = newData_1;
                            _this.loading = filtered.reduce(function (acc, item) {
                                var _a;
                                return (__assign(__assign({}, acc), (_a = {}, _a[item] = false, _a)));
                            }, __assign({}, _this.loading));
                        });
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return BalanceListStore;
}());
export { BalanceListStore };
