import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, Typography } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import cardBlack from "@/shared/img/cards/reap-black.png";
import { PickCardAdvantage } from "@/pages/CardPage/components/PickCard/PickCardAdvantage";
import { PickCardColor } from "@/pages/CardPage/components/PickCard/PickCardColor";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CardOrderStore } from "@/pages/CardOrderPage/stores/CardOrderStore";
import { DiscountStore } from "@/shared/stores/DiscountStore";
export var PickCard = observer(function (_a) {
    var offer = _a.offer;
    var media = useMedia();
    var cardOrder = useStoreContext(CardOrderStore);
    var discount = useStoreContext(DiscountStore);
    var hasDiscount = discount.list && discount.list.length > 0;
    var discountedAmount = hasDiscount && discount.list[0].discount_percent
        ? (Number(offer.virtualPriceAmount) *
            (100 - discount.list[0].discount_percent)) /
            100
        : offer.virtualPriceAmount;
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { borderRadius: "22px", padding: "32px", bgColor: "general-white" },
            React.createElement(Box, { display: "grid", gap: "32px", gridTemplateColumns: media.phone ? "1fr" : "2fr 4fr" },
                React.createElement(Box, { width: "100%" },
                    React.createElement(Box, { display: "flex", justifyContent: "center", marginTop: "24px" },
                        React.createElement("img", { src: cardBlack, alt: "card-preview" }))),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "16px" },
                        React.createElement(Typography, { type: "text32Bold" }, "Visa Platinum"),
                        React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "flex-start" },
                            hasDiscount && (React.createElement(Typography, { sx: {
                                    fontSize: "24px",
                                    textDecoration: "line-through",
                                }, color: "black-500" }, offer === null || offer === void 0 ? void 0 :
                                offer.virtualPriceCurrency,
                                " ", offer === null || offer === void 0 ? void 0 :
                                offer.virtualPriceAmount)),
                            React.createElement(Typography, { type: "text24" }, offer === null || offer === void 0 ? void 0 :
                                offer.virtualPriceCurrency,
                                " ",
                                Number(discountedAmount).toFixed(2)))),
                    React.createElement(Box, { width: "100%", display: "flex", flexDirection: "column", gap: "16px" },
                        React.createElement(PickCardAdvantage, { icon: "Worldwide", title: "Use worldwide", text: "You can use the card anywhere in the world where VISA is accepted" }),
                        React.createElement(PickCardAdvantage, { icon: "Dollar", title: "USD card", text: "Top up your card with selection of fiat and crypto currencies" }),
                        React.createElement(PickCardAdvantage, { icon: "Nfc", title: "Apple Pay and Google Pay ready", text: "Empower your device by connecting your card for contactless payments" })),
                    React.createElement(PickCardColor, { designs: offer.allowedCardDesigns })))),
        React.createElement(Box, { display: "flex", justifyContent: "center" },
            React.createElement(Button, { width: "100%", sx: { maxWidth: "328px" }, size: "l", onClick: cardOrder.nextStep }, "Get card"))));
});
