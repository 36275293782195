import React from "react";
import { observer } from "mobx-react-lite";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Input } from "@/shared/components/Input";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { Amount } from "@/shared/components/Amount";
export var ChooseCryptoList = observer(function (_a) {
    var onCurrencySelect = _a.onCurrencySelect;
    var currencyList = useStoreContext(CurrencyListStore);
    var balances = useStore(BalanceListStore, currencyList);
    var accountList = useStoreContext(AccountListStore);
    var account = accountList.checking;
    var _b = React.useState(""), searchTerm = _b[0], setSearchTerm = _b[1];
    React.useEffect(function () {
        if (account) {
            balances.sync(account.account);
        }
    }, [account, balances]);
    var currencies = currencyList.withWalletEnabled.filter(function (currency) { return currency.type === "CRYPTO"; });
    var filteredCurrencies = currencies.filter(function (currency) {
        var _a, _b;
        return ((_a = currency === null || currency === void 0 ? void 0 : currency.shortName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchTerm.toLowerCase())) ||
            ((_b = currency === null || currency === void 0 ? void 0 : currency.description) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(searchTerm.toLowerCase()));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, null,
            React.createElement(Input, { variant: "filled", placeholder: "Search", value: searchTerm, onChange: function (e) {
                    setSearchTerm(e.target.value);
                }, InputProps: {
                    startAdornment: (React.createElement(BaseIcon, { icon: "Search", color: "black-500", size: 24 })),
                } }),
            React.createElement(Box, { borderRadius: "22px", padding: "32px 20px", bgColor: "general-white", marginTop: "32px" }, filteredCurrencies.map(function (currency) {
                var _a, _b;
                var slug = currency.slug;
                if (!slug) {
                    return null; // Пропускаем, если slug отсутствует
                }
                return (React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", padding: "8px 12px", key: currency.description, sx: {
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "#F6F7F8",
                        },
                    }, borderRadius: "16px", onClick: function () {
                        onCurrencySelect(currency);
                    } },
                    React.createElement(Box, { display: "flex", gap: "12px" },
                        React.createElement(TokenIcon, { symbol: slug, size: 44, iconUrl: currency.iconUrl }),
                        React.createElement(Box, { display: "flex", flexDirection: "column" },
                            React.createElement(Typography, { type: "text16Bold" }, currency.description),
                            React.createElement(Typography, { type: "text14", color: "black-500" }, currency.shortName))),
                    React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center" },
                        React.createElement(Typography, { type: "text16" },
                            currency.shortName,
                            " ",
                            React.createElement(Amount, { from: slug, to: slug, amount: (_a = balances.data[slug]) === null || _a === void 0 ? void 0 : _a.balance })),
                        React.createElement(Typography, { type: "text14", color: "black-500" },
                            React.createElement(Amount, { from: slug, amount: (_b = balances.data[slug]) === null || _b === void 0 ? void 0 : _b.balance })))));
            })))));
});
