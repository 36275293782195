/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/** Status of account */
export var AccountStatus;
(function (AccountStatus) {
    AccountStatus["INIT"] = "INIT";
    AccountStatus["PENDING"] = "PENDING";
    AccountStatus["ACTIVE"] = "ACTIVE";
    AccountStatus["INACTIVE"] = "INACTIVE";
    AccountStatus["CLOSED"] = "CLOSED";
    AccountStatus["FROZEN"] = "FROZEN";
})(AccountStatus || (AccountStatus = {}));
export var AccountType;
(function (AccountType) {
    AccountType["OTHER"] = "OTHER";
    AccountType["CHECKING"] = "CHECKING";
    AccountType["SAVING"] = "SAVING";
    AccountType["INVESTMENT"] = "INVESTMENT";
    AccountType["PFSCARD"] = "PFSCARD";
    AccountType["LAYER2"] = "LAYER2";
})(AccountType || (AccountType = {}));
export var AccountUserRole;
(function (AccountUserRole) {
    AccountUserRole["OWNER"] = "OWNER";
    AccountUserRole["READ"] = "READ";
    AccountUserRole["WRITE"] = "WRITE";
})(AccountUserRole || (AccountUserRole = {}));
export var BalanceLogDirectionEnum;
(function (BalanceLogDirectionEnum) {
    BalanceLogDirectionEnum["CREDIT"] = "CREDIT";
    BalanceLogDirectionEnum["DEBIT"] = "DEBIT";
})(BalanceLogDirectionEnum || (BalanceLogDirectionEnum = {}));
export var BalanceLogReasonType;
(function (BalanceLogReasonType) {
    BalanceLogReasonType["ORDER"] = "ORDER";
    BalanceLogReasonType["TRANSFER"] = "TRANSFER";
    BalanceLogReasonType["DEPOSIT"] = "DEPOSIT";
    BalanceLogReasonType["BTC_DEPOSIT"] = "BTC_DEPOSIT";
    BalanceLogReasonType["ETH_DEPOSIT"] = "ETH_DEPOSIT";
    BalanceLogReasonType["TON_DEPOSIT"] = "TON_DEPOSIT";
    BalanceLogReasonType["BTC_WITHDRAWAL_REQUEST"] = "BTC_WITHDRAWAL_REQUEST";
    BalanceLogReasonType["BTC_WITHDRAWAL_REJECT"] = "BTC_WITHDRAWAL_REJECT";
    BalanceLogReasonType["BTC_WITHDRAWAL_FAIL"] = "BTC_WITHDRAWAL_FAIL";
    BalanceLogReasonType["ETH_WITHDRAWAL_REQUEST"] = "ETH_WITHDRAWAL_REQUEST";
    BalanceLogReasonType["ETH_WITHDRAWAL_REJECT"] = "ETH_WITHDRAWAL_REJECT";
    BalanceLogReasonType["ETH_WITHDRAWAL_FAIL"] = "ETH_WITHDRAWAL_FAIL";
    BalanceLogReasonType["TON_WITHDRAWAL_REQUEST"] = "TON_WITHDRAWAL_REQUEST";
    BalanceLogReasonType["TON_WITHDRAWAL_REJECT"] = "TON_WITHDRAWAL_REJECT";
    BalanceLogReasonType["TON_WITHDRAWAL_FAIL"] = "TON_WITHDRAWAL_FAIL";
    BalanceLogReasonType["FIAT_WITHDRAWAL_REQUEST"] = "FIAT_WITHDRAWAL_REQUEST";
    BalanceLogReasonType["FIAT_WITHDRAWAL_REJECT"] = "FIAT_WITHDRAWAL_REJECT";
    BalanceLogReasonType["DEPOSIT_FEE"] = "DEPOSIT_FEE";
    BalanceLogReasonType["WITHDRAWAL"] = "WITHDRAWAL";
    BalanceLogReasonType["WITHDRAWAL_FEE"] = "WITHDRAWAL_FEE";
    BalanceLogReasonType["REJECTED_WITHDRAWAL_FEE"] = "REJECTED_WITHDRAWAL_FEE";
    BalanceLogReasonType["FAILED_WITHDRAWAL_FEE"] = "FAILED_WITHDRAWAL_FEE";
    BalanceLogReasonType["ORDER_FEE"] = "ORDER_FEE";
    BalanceLogReasonType["DIRECT_EXCHANGE"] = "DIRECT_EXCHANGE";
    BalanceLogReasonType["DIRECT_EXCHANGE_FEE"] = "DIRECT_EXCHANGE_FEE";
    BalanceLogReasonType["LOCK_BALANCE"] = "LOCK_BALANCE";
    BalanceLogReasonType["UNLOCK_BALANCE"] = "UNLOCK_BALANCE";
    BalanceLogReasonType["UNKNOWN"] = "UNKNOWN";
})(BalanceLogReasonType || (BalanceLogReasonType = {}));
export var CounterpartyProfileType;
(function (CounterpartyProfileType) {
    CounterpartyProfileType["INDIVIDUAL"] = "INDIVIDUAL";
    CounterpartyProfileType["CORPORATION"] = "CORPORATION";
    CounterpartyProfileType["UNKNOWN"] = "UNKNOWN";
})(CounterpartyProfileType || (CounterpartyProfileType = {}));
/**
 * Type of wallet that will hold/custody these funds for the recipient owner (e.g. Custodian, Private Wallet etc)
 * @example "INSTITUTION"
 */
export var CounterpartyWalletType;
(function (CounterpartyWalletType) {
    CounterpartyWalletType["INSTITUTION"] = "INSTITUTION";
    CounterpartyWalletType["OTHER"] = "OTHER";
    CounterpartyWalletType["UNKNOWN"] = "UNKNOWN";
})(CounterpartyWalletType || (CounterpartyWalletType = {}));
export var DepositStatusEnum;
(function (DepositStatusEnum) {
    DepositStatusEnum["UNDEFINED"] = "UNDEFINED";
    DepositStatusEnum["INITIAL"] = "INITIAL";
    DepositStatusEnum["PENDING"] = "PENDING";
    DepositStatusEnum["APPROVED"] = "APPROVED";
    DepositStatusEnum["PROCESSING"] = "PROCESSING";
    DepositStatusEnum["PROCESSED"] = "PROCESSED";
    DepositStatusEnum["FAILING"] = "FAILING";
    DepositStatusEnum["FAILED"] = "FAILED";
    DepositStatusEnum["REJECTING"] = "REJECTING";
    DepositStatusEnum["REJECTED"] = "REJECTED";
})(DepositStatusEnum || (DepositStatusEnum = {}));
/**
 * Status of exchange:
 * * `UNDEFINED` - status not set
 * * `ACCEPTED` - request is accepted, can be canceled
 * * `PENDING` - request is processing
 * * `SUCCESS` - request is success (final status)
 * * `FAILED` - request is failed, see `note` for comments (final status)
 * @example "SUCCESS"
 */
export var DirectExchangeStatus;
(function (DirectExchangeStatus) {
    DirectExchangeStatus["UNDEFINED"] = "UNDEFINED";
    DirectExchangeStatus["ACCEPTED"] = "ACCEPTED";
    DirectExchangeStatus["PENDING"] = "PENDING";
    DirectExchangeStatus["APPROVING"] = "APPROVING";
    DirectExchangeStatus["APPROVED"] = "APPROVED";
    DirectExchangeStatus["PROCESSING"] = "PROCESSING";
    DirectExchangeStatus["SUCCESS"] = "SUCCESS";
    DirectExchangeStatus["FAILED"] = "FAILED";
    DirectExchangeStatus["REJECTING"] = "REJECTING";
    DirectExchangeStatus["REJECTED"] = "REJECTED";
})(DirectExchangeStatus || (DirectExchangeStatus = {}));
export var HttpStatus;
(function (HttpStatus) {
    HttpStatus["Value100CONTINUE"] = "100 CONTINUE";
    HttpStatus["Value101SWITCHINGPROTOCOLS"] = "101 SWITCHING_PROTOCOLS";
    HttpStatus["Value102PROCESSING"] = "102 PROCESSING";
    HttpStatus["Value103EARLYHINTS"] = "103 EARLY_HINTS";
    HttpStatus["Value103CHECKPOINT"] = "103 CHECKPOINT";
    HttpStatus["Value200OK"] = "200 OK";
    HttpStatus["Value201CREATED"] = "201 CREATED";
    HttpStatus["Value202ACCEPTED"] = "202 ACCEPTED";
    HttpStatus["Value203NONAUTHORITATIVEINFORMATION"] = "203 NON_AUTHORITATIVE_INFORMATION";
    HttpStatus["Value204NOCONTENT"] = "204 NO_CONTENT";
    HttpStatus["Value205RESETCONTENT"] = "205 RESET_CONTENT";
    HttpStatus["Value206PARTIALCONTENT"] = "206 PARTIAL_CONTENT";
    HttpStatus["Value207MULTISTATUS"] = "207 MULTI_STATUS";
    HttpStatus["Value208ALREADYREPORTED"] = "208 ALREADY_REPORTED";
    HttpStatus["Value226IMUSED"] = "226 IM_USED";
    HttpStatus["Value300MULTIPLECHOICES"] = "300 MULTIPLE_CHOICES";
    HttpStatus["Value301MOVEDPERMANENTLY"] = "301 MOVED_PERMANENTLY";
    HttpStatus["Value302FOUND"] = "302 FOUND";
    HttpStatus["Value302MOVEDTEMPORARILY"] = "302 MOVED_TEMPORARILY";
    HttpStatus["Value303SEEOTHER"] = "303 SEE_OTHER";
    HttpStatus["Value304NOTMODIFIED"] = "304 NOT_MODIFIED";
    HttpStatus["Value305USEPROXY"] = "305 USE_PROXY";
    HttpStatus["Value307TEMPORARYREDIRECT"] = "307 TEMPORARY_REDIRECT";
    HttpStatus["Value308PERMANENTREDIRECT"] = "308 PERMANENT_REDIRECT";
    HttpStatus["Value400BADREQUEST"] = "400 BAD_REQUEST";
    HttpStatus["Value401UNAUTHORIZED"] = "401 UNAUTHORIZED";
    HttpStatus["Value402PAYMENTREQUIRED"] = "402 PAYMENT_REQUIRED";
    HttpStatus["Value403FORBIDDEN"] = "403 FORBIDDEN";
    HttpStatus["Value404NOTFOUND"] = "404 NOT_FOUND";
    HttpStatus["Value405METHODNOTALLOWED"] = "405 METHOD_NOT_ALLOWED";
    HttpStatus["Value406NOTACCEPTABLE"] = "406 NOT_ACCEPTABLE";
    HttpStatus["Value407PROXYAUTHENTICATIONREQUIRED"] = "407 PROXY_AUTHENTICATION_REQUIRED";
    HttpStatus["Value408REQUESTTIMEOUT"] = "408 REQUEST_TIMEOUT";
    HttpStatus["Value409CONFLICT"] = "409 CONFLICT";
    HttpStatus["Value410GONE"] = "410 GONE";
    HttpStatus["Value411LENGTHREQUIRED"] = "411 LENGTH_REQUIRED";
    HttpStatus["Value412PRECONDITIONFAILED"] = "412 PRECONDITION_FAILED";
    HttpStatus["Value413PAYLOADTOOLARGE"] = "413 PAYLOAD_TOO_LARGE";
    HttpStatus["Value413REQUESTENTITYTOOLARGE"] = "413 REQUEST_ENTITY_TOO_LARGE";
    HttpStatus["Value414URITOOLONG"] = "414 URI_TOO_LONG";
    HttpStatus["Value414REQUESTURITOOLONG"] = "414 REQUEST_URI_TOO_LONG";
    HttpStatus["Value415UNSUPPORTEDMEDIATYPE"] = "415 UNSUPPORTED_MEDIA_TYPE";
    HttpStatus["Value416REQUESTEDRANGENOTSATISFIABLE"] = "416 REQUESTED_RANGE_NOT_SATISFIABLE";
    HttpStatus["Value417EXPECTATIONFAILED"] = "417 EXPECTATION_FAILED";
    HttpStatus["Value418IAMATEAPOT"] = "418 I_AM_A_TEAPOT";
    HttpStatus["Value419INSUFFICIENTSPACEONRESOURCE"] = "419 INSUFFICIENT_SPACE_ON_RESOURCE";
    HttpStatus["Value420METHODFAILURE"] = "420 METHOD_FAILURE";
    HttpStatus["Value421DESTINATIONLOCKED"] = "421 DESTINATION_LOCKED";
    HttpStatus["Value422UNPROCESSABLEENTITY"] = "422 UNPROCESSABLE_ENTITY";
    HttpStatus["Value423LOCKED"] = "423 LOCKED";
    HttpStatus["Value424FAILEDDEPENDENCY"] = "424 FAILED_DEPENDENCY";
    HttpStatus["Value425TOOEARLY"] = "425 TOO_EARLY";
    HttpStatus["Value426UPGRADEREQUIRED"] = "426 UPGRADE_REQUIRED";
    HttpStatus["Value428PRECONDITIONREQUIRED"] = "428 PRECONDITION_REQUIRED";
    HttpStatus["Value429TOOMANYREQUESTS"] = "429 TOO_MANY_REQUESTS";
    HttpStatus["Value431REQUESTHEADERFIELDSTOOLARGE"] = "431 REQUEST_HEADER_FIELDS_TOO_LARGE";
    HttpStatus["Value451UNAVAILABLEFORLEGALREASONS"] = "451 UNAVAILABLE_FOR_LEGAL_REASONS";
    HttpStatus["Value500INTERNALSERVERERROR"] = "500 INTERNAL_SERVER_ERROR";
    HttpStatus["Value501NOTIMPLEMENTED"] = "501 NOT_IMPLEMENTED";
    HttpStatus["Value502BADGATEWAY"] = "502 BAD_GATEWAY";
    HttpStatus["Value503SERVICEUNAVAILABLE"] = "503 SERVICE_UNAVAILABLE";
    HttpStatus["Value504GATEWAYTIMEOUT"] = "504 GATEWAY_TIMEOUT";
    HttpStatus["Value505HTTPVERSIONNOTSUPPORTED"] = "505 HTTP_VERSION_NOT_SUPPORTED";
    HttpStatus["Value506VARIANTALSONEGOTIATES"] = "506 VARIANT_ALSO_NEGOTIATES";
    HttpStatus["Value507INSUFFICIENTSTORAGE"] = "507 INSUFFICIENT_STORAGE";
    HttpStatus["Value508LOOPDETECTED"] = "508 LOOP_DETECTED";
    HttpStatus["Value509BANDWIDTHLIMITEXCEEDED"] = "509 BANDWIDTH_LIMIT_EXCEEDED";
    HttpStatus["Value510NOTEXTENDED"] = "510 NOT_EXTENDED";
    HttpStatus["Value511NETWORKAUTHENTICATIONREQUIRED"] = "511 NETWORK_AUTHENTICATION_REQUIRED";
})(HttpStatus || (HttpStatus = {}));
export var LineOfBusiness;
(function (LineOfBusiness) {
    LineOfBusiness["ARTS_AND_ANTIQUES"] = "ARTS_AND_ANTIQUES";
    LineOfBusiness["AGRICULTURE"] = "AGRICULTURE";
    LineOfBusiness["AUDIT_VIDEO"] = "AUDIT_VIDEO";
    LineOfBusiness["AUTOMOTIVE"] = "AUTOMOTIVE";
    LineOfBusiness["BANK_CREDIT_UNION"] = "BANK_CREDIT_UNION";
    LineOfBusiness["BIOTECH_PHARMACEUTICAL"] = "BIOTECH_PHARMACEUTICAL";
    LineOfBusiness["BOOKSELLER"] = "BOOKSELLER";
    LineOfBusiness["CONSTRUCTION"] = "CONSTRUCTION";
    LineOfBusiness["CASINOS_GAMBLING"] = "CASINOS_GAMBLING";
    LineOfBusiness["CLOTHING"] = "CLOTHING";
    LineOfBusiness["CONSULTING_PROFESSIONAL_SERVICES"] = "CONSULTING_PROFESSIONAL_SERVICES";
    LineOfBusiness["CRYPTOCURRENCY"] = "CRYPTOCURRENCY";
    LineOfBusiness["ECIGARETTES_TOBACCO"] = "ECIGARETTES_TOBACCO";
    LineOfBusiness["ENERGY_CHEMICAL_FUEL"] = "ENERGY_CHEMICAL_FUEL";
    LineOfBusiness["ENGINEERING"] = "ENGINEERING";
    LineOfBusiness["ADULT_ENTERTAINMENT"] = "ADULT_ENTERTAINMENT";
    LineOfBusiness["FINANCIAL_AND_CORPORATE_SERVICES"] = "FINANCIAL_AND_CORPORATE_SERVICES";
    LineOfBusiness["FOOD_AND_BEVERAGE"] = "FOOD_AND_BEVERAGE";
    LineOfBusiness["FREIGHT_MARINE_SHIPPING_SERVICES"] = "FREIGHT_MARINE_SHIPPING_SERVICES";
    LineOfBusiness["GIFTWARE_SEASONAL"] = "GIFTWARE_SEASONAL";
    LineOfBusiness["GOVERNMENT_PUBLIC_SERVICES"] = "GOVERNMENT_PUBLIC_SERVICES";
    LineOfBusiness["HIGH_TECH_SOFTWARE_TELECOMS"] = "HIGH_TECH_SOFTWARE_TELECOMS";
    LineOfBusiness["HOSPITALITY"] = "HOSPITALITY";
    LineOfBusiness["HOUSEHOLD_GOOD_FURNITURE"] = "HOUSEHOLD_GOOD_FURNITURE";
    LineOfBusiness["HUMAN_RESOURCE_SERVICES"] = "HUMAN_RESOURCE_SERVICES";
    LineOfBusiness["INDUSTRIAL_EQUIPMENT"] = "INDUSTRIAL_EQUIPMENT";
    LineOfBusiness["INFORMATION_TECHNOLOGY"] = "INFORMATION_TECHNOLOGY";
    LineOfBusiness["INSURANCE"] = "INSURANCE";
    LineOfBusiness["JEWELLERY_OPTICAL"] = "JEWELLERY_OPTICAL";
    LineOfBusiness["LAW"] = "LAW";
    LineOfBusiness["MANUFACTURING"] = "MANUFACTURING";
    LineOfBusiness["MARKETING"] = "MARKETING";
    LineOfBusiness["MEDIA_PUBLISHING"] = "MEDIA_PUBLISHING";
    LineOfBusiness["MEDICAL_HEALTHCARE"] = "MEDICAL_HEALTHCARE";
    LineOfBusiness["MEDICAL_TOURISM"] = "MEDICAL_TOURISM";
    LineOfBusiness["MINING"] = "MINING";
    LineOfBusiness["MONEY_SERVICE_BUSINESS"] = "MONEY_SERVICE_BUSINESS";
    LineOfBusiness["NON_PROFIT_CHARITY"] = "NON_PROFIT_CHARITY";
    LineOfBusiness["ONLINE_RETAIL"] = "ONLINE_RETAIL";
    LineOfBusiness["PAYROLL"] = "PAYROLL";
    LineOfBusiness["PENSION"] = "PENSION";
    LineOfBusiness["PERSONAL_CARE_PRODUCTS"] = "PERSONAL_CARE_PRODUCTS";
    LineOfBusiness["PHOTOGRAPHY"] = "PHOTOGRAPHY";
    LineOfBusiness["REAL_ESTATE"] = "REAL_ESTATE";
    LineOfBusiness["RECREATIONAL_ACTIVITIES"] = "RECREATIONAL_ACTIVITIES";
    LineOfBusiness["RELIGIOUS_ORGANISATION"] = "RELIGIOUS_ORGANISATION";
    LineOfBusiness["RETAIL"] = "RETAIL";
    LineOfBusiness["SECURITY"] = "SECURITY";
    LineOfBusiness["SPORTING_RECREATIONAL_PRODUCTS"] = "SPORTING_RECREATIONAL_PRODUCTS";
    LineOfBusiness["TRANSPORT"] = "TRANSPORT";
    LineOfBusiness["UNIVERSITY_EDUCATION"] = "UNIVERSITY_EDUCATION";
    LineOfBusiness["UTILITIES"] = "UTILITIES";
    LineOfBusiness["WASTE_MANAGEMENT"] = "WASTE_MANAGEMENT";
    LineOfBusiness["WINE_LIQUOR"] = "WINE_LIQUOR";
    LineOfBusiness["TECHNICAL_SUPPORT"] = "TECHNICAL_SUPPORT";
    LineOfBusiness["OTHER"] = "OTHER";
})(LineOfBusiness || (LineOfBusiness = {}));
export var OperationType;
(function (OperationType) {
    OperationType["WITHDRAW"] = "WITHDRAW";
    OperationType["DEPOSIT"] = "DEPOSIT";
    OperationType["TRANSFER"] = "TRANSFER";
    OperationType["EXCHANGE"] = "EXCHANGE";
})(OperationType || (OperationType = {}));
export var QrResponseType;
(function (QrResponseType) {
    QrResponseType["WITHDRAW"] = "WITHDRAW";
})(QrResponseType || (QrResponseType = {}));
export var RelationType;
(function (RelationType) {
    RelationType["SPOUSE"] = "SPOUSE";
    RelationType["EX_SPOUSE"] = "EX_SPOUSE";
    RelationType["CHILDREN"] = "CHILDREN";
    RelationType["PARENT"] = "PARENT";
    RelationType["SIBLING"] = "SIBLING";
    RelationType["RELATIVE"] = "RELATIVE";
    RelationType["SELF"] = "SELF";
    RelationType["FRIEND"] = "FRIEND";
    RelationType["BUSINESS_PARTNER"] = "BUSINESS_PARTNER";
    RelationType["CUSTOMER"] = "CUSTOMER";
    RelationType["EMPLOYEE"] = "EMPLOYEE";
    RelationType["BRANCH_OFFICE"] = "BRANCH_OFFICE";
    RelationType["SUBSIDIARY_COMPANY"] = "SUBSIDIARY_COMPANY";
    RelationType["HOLDING_COMPANY"] = "HOLDING_COMPANY";
    RelationType["SUPPLIER"] = "SUPPLIER";
    RelationType["CREDITOR"] = "CREDITOR";
    RelationType["DEBTOR"] = "DEBTOR";
    RelationType["FRANCHISEE"] = "FRANCHISEE";
    RelationType["NOT_RELATED"] = "NOT_RELATED";
})(RelationType || (RelationType = {}));
export var TransferStatus;
(function (TransferStatus) {
    TransferStatus["INIT"] = "INIT";
    TransferStatus["PLACED"] = "PLACED";
    TransferStatus["PROCESSING"] = "PROCESSING";
    TransferStatus["REJECTED"] = "REJECTED";
    TransferStatus["COMPLETED"] = "COMPLETED";
    TransferStatus["FAILED"] = "FAILED";
})(TransferStatus || (TransferStatus = {}));
/** @example "PROCESSED" */
export var WithdrawStatus;
(function (WithdrawStatus) {
    WithdrawStatus["INITIAL"] = "INITIAL";
    WithdrawStatus["PROCESSING"] = "PROCESSING";
    WithdrawStatus["WITHDRAWN"] = "WITHDRAWN";
    WithdrawStatus["PENDING"] = "PENDING";
    WithdrawStatus["CANCELED"] = "CANCELED";
    WithdrawStatus["FAILED"] = "FAILED";
    WithdrawStatus["REJECTED"] = "REJECTED";
    WithdrawStatus["APPROVED"] = "APPROVED";
    WithdrawStatus["PLACED"] = "PLACED";
    WithdrawStatus["SUCCESS"] = "SUCCESS";
    WithdrawStatus["PROCESSED"] = "PROCESSED";
    WithdrawStatus["FAILING"] = "FAILING";
    WithdrawStatus["REJECTING"] = "REJECTING";
})(WithdrawStatus || (WithdrawStatus = {}));
export var WithdrawType;
(function (WithdrawType) {
    WithdrawType["CRYPTO"] = "CRYPTO";
    WithdrawType["BANK_TRANSFER"] = "BANK_TRANSFER";
    WithdrawType["ATM"] = "ATM";
    WithdrawType["PAY_SPOT"] = "PAY_SPOT";
    WithdrawType["COUNTERPARTY"] = "COUNTERPARTY";
})(WithdrawType || (WithdrawType = {}));
export var CounterpartyRailType;
(function (CounterpartyRailType) {
    CounterpartyRailType["ACH"] = "ACH";
    CounterpartyRailType["FEDWIRE"] = "FEDWIRE";
    CounterpartyRailType["SWIFT"] = "SWIFT";
    CounterpartyRailType["EFT"] = "EFT";
    CounterpartyRailType["SEPA"] = "SEPA";
    CounterpartyRailType["IMPS"] = "IMPS";
    CounterpartyRailType["CRYPTO"] = "CRYPTO";
})(CounterpartyRailType || (CounterpartyRailType = {}));
/**
 * Type of counterparty
 * @example "CRYPTO"
 */
export var CounterpartyType;
(function (CounterpartyType) {
    CounterpartyType["FIAT"] = "FIAT";
    CounterpartyType["CRYPTO"] = "CRYPTO";
})(CounterpartyType || (CounterpartyType = {}));
import axios from "axios";
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
var HttpClient = /** @class */ (function () {
    function HttpClient(_a) {
        if (_a === void 0) { _a = {}; }
        var _this = this;
        var securityWorker = _a.securityWorker, secure = _a.secure, format = _a.format, axiosConfig = __rest(_a, ["securityWorker", "secure", "format"]);
        this.securityData = null;
        this.setSecurityData = function (data) {
            _this.securityData = data;
        };
        this.request = function (_a) { return __awaiter(_this, void 0, Promise, function () {
            var secureParams, _b, requestParams, responseFormat;
            var secure = _a.secure, path = _a.path, type = _a.type, query = _a.query, format = _a.format, body = _a.body, params = __rest(_a, ["secure", "path", "type", "query", "format", "body"]);
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (typeof secure === "boolean" ? secure : this.secure) &&
                            this.securityWorker;
                        if (!_b) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.securityWorker(this.securityData)];
                    case 1:
                        _b = (_c.sent());
                        _c.label = 2;
                    case 2:
                        secureParams = (_b) ||
                            {};
                        requestParams = this.mergeRequestParams(params, secureParams);
                        responseFormat = format || this.format || undefined;
                        if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
                            body = this.createFormData(body);
                        }
                        if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
                            body = JSON.stringify(body);
                        }
                        return [2 /*return*/, this.instance.request(__assign(__assign({}, requestParams), { headers: __assign(__assign({}, (requestParams.headers || {})), (type ? { "Content-Type": type } : {})), params: query, responseType: responseFormat, data: body, url: path }))];
                }
            });
        }); };
        this.instance = axios.create(__assign(__assign({}, axiosConfig), { baseURL: axiosConfig.baseURL || "https://api.sandbox.finharbor.io/wallet" }));
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }
    HttpClient.prototype.mergeRequestParams = function (params1, params2) {
        var method = params1.method || (params2 && params2.method);
        return __assign(__assign(__assign(__assign({}, this.instance.defaults), params1), (params2 || {})), { headers: __assign(__assign(__assign({}, ((method && this.instance.defaults.headers[method.toLowerCase()]) || {})), (params1.headers || {})), ((params2 && params2.headers) || {})) });
    };
    HttpClient.prototype.stringifyFormItem = function (formItem) {
        if (typeof formItem === "object" && formItem !== null) {
            return JSON.stringify(formItem);
        }
        else {
            return "".concat(formItem);
        }
    };
    HttpClient.prototype.createFormData = function (input) {
        var _this = this;
        if (input instanceof FormData) {
            return input;
        }
        return Object.keys(input || {}).reduce(function (formData, key) {
            var property = input[key];
            var propertyContent = property instanceof Array ? property : [property];
            for (var _i = 0, propertyContent_1 = propertyContent; _i < propertyContent_1.length; _i++) {
                var formItem = propertyContent_1[_i];
                var isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : _this.stringifyFormItem(formItem));
            }
            return formData;
        }, new FormData());
    };
    return HttpClient;
}());
export { HttpClient };
/**
 * @title Wallet
 * @version 0.3.0
 * @baseUrl https://api.sandbox.finharbor.io/wallet
 *
 * API for interactions with account balances, like creating spend operations and retrieve all corresponding operations.
 */
var WalletApi = /** @class */ (function (_super) {
    __extends(WalletApi, _super);
    function WalletApi() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.counterparty = {
            /**
             * @description Get counterparty by id for the current user.
             *
             * @tags Counterparty
             * @name GetCounterpartiesById
             * @summary Get counterparty by id
             * @request GET:/counterparty/{uuid}
             * @secure
             */
            getCounterpartiesById: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/counterparty/".concat(uuid), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Update a counterparty for the current user. Only counterparty with status INIT or ERROR can be updated.
             *
             * @tags Counterparty
             * @name UpdateCounterparty
             * @summary Update a counterparty.
             * @request PUT:/counterparty/{uuid}
             * @secure
             */
            updateCounterparty: function (uuid, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/counterparty/".concat(uuid), method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Counterparty
             * @name DeleteCounterparty
             * @request DELETE:/counterparty/{uuid}
             * @secure
             */
            deleteCounterparty: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/counterparty/".concat(uuid), method: "DELETE", secure: true, format: "json" }, params));
            },
            /**
             * @description Get all counterparties for the current user.
             *
             * @tags Counterparty
             * @name GetCounterparties
             * @summary Get counterparties for current user
             * @request GET:/counterparty
             * @secure
             */
            getCounterparties: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/counterparty", method: "GET", query: query, secure: true, format: "json" }, params));
            },
            /**
             * @description Create a counterparty for the current user.
             *
             * @tags Counterparty
             * @name CreateCounterparty
             * @summary Create a counterparty
             * @request POST:/counterparty
             * @secure
             */
            createCounterparty: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/counterparty", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
        };
        _this.account = {
            /**
             * @description Returns 200 with AccountInfo objects if success
             *
             * @tags Account
             * @name GetAccount
             * @summary Get account by id
             * @request GET:/account/{accountId}
             * @secure
             */
            getAccount: function (accountId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/account/".concat(accountId), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Allows to update shortname of account
             *
             * @tags Account
             * @name UpdateAccount
             * @summary Update account by id
             * @request PUT:/account/{accountId}
             * @secure
             */
            updateAccount: function (accountId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/account/".concat(accountId), method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Returns 200 with AccountInfo objects if success
             *
             * @tags Account
             * @name ListAccount
             * @summary List all accounts
             * @request GET:/account
             * @secure
             */
            listAccount: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/account", method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Returns 200 with AccountInfo objects if success
             *
             * @tags Account
             * @name CreateAccount
             * @summary Create an account
             * @request POST:/account
             * @secure
             */
            createAccount: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/account", method: "POST", query: query, secure: true, format: "json" }, params));
            },
        };
        _this.withdraw = {
            /**
             * @description Get list of withdraws of current user with optional filtering by account id
             *
             * @tags Operations
             * @name GetAllWithdraw
             * @summary List all withdraws
             * @request GET:/withdraw
             * @secure
             */
            getAllWithdraw: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/withdraw", method: "GET", query: query, secure: true, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Operations
             * @name CreateWithdraw
             * @summary Create a withdraw request
             * @request POST:/withdraw
             * @secure
             */
            createWithdraw: function (data, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/withdraw", method: "POST", query: query, body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Operations
             * @name GetWithdraw
             * @summary Retrieve a withdraw
             * @request GET:/withdraw/{uuid}
             * @secure
             */
            getWithdraw: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/withdraw/".concat(uuid), method: "GET", secure: true, format: "json" }, params));
            },
        };
        _this.transfer = {
            /**
             * @description Get list of transfers of current user with optional filtering by account id
             *
             * @tags Operations
             * @name ListTransfers
             * @summary List all transfers
             * @request GET:/transfer
             * @secure
             */
            listTransfers: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/transfer", method: "GET", query: query, secure: true, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Operations
             * @name CreateTransfer
             * @summary Create transfer between accounts
             * @request POST:/transfer
             * @secure
             */
            createTransfer: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/transfer", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Confirm existing transfer
             *
             * @tags Operations
             * @name ConfirmTransfer
             * @summary Confirm a transfer
             * @request POST:/transfer/{uuid}/confirm
             * @secure
             */
            confirmTransfer: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/transfer/".concat(uuid, "/confirm"), method: "POST", secure: true, format: "json" }, params));
            },
            /**
             * @description Retrieve a transfer between accounts by uuid
             *
             * @tags Operations
             * @name GetTransfer
             * @summary Retrieve a transfer
             * @request GET:/transfer/{uuid}
             * @secure
             */
            getTransfer: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/transfer/".concat(uuid), method: "GET", secure: true, format: "json" }, params));
            },
        };
        _this.qrCode = {
            /**
             * No description
             *
             * @tags QR Codes
             * @name ScanCode
             * @summary Scan QR code
             * @request POST:/qr-code/scan
             * @secure
             */
            scanCode: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/qr-code/scan", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
        };
        _this.layer2 = {
            /**
             * No description
             *
             * @tags layer-2-webhook-controller
             * @name Webhook
             * @request POST:/layer2/webhook
             */
            webhook: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/layer2/webhook", method: "POST", body: data, type: ContentType.Json }, params));
            },
        };
        _this.directExchange = {
            /**
             * @description List of exchanges of current user with optional filtering by account id
             *
             * @tags Operations
             * @name GetAllDirectExchange
             * @summary List all exchanges
             * @request GET:/direct-exchange
             * @secure
             */
            getAllDirectExchange: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/direct-exchange", method: "GET", query: query, secure: true, format: "json" }, params));
            },
            /**
             * @description Create request for exchange one currency to another on user account. `dryRun` request param allows to check resulting rate and fee amount
             *
             * @tags Operations
             * @name CreateDirectExchange
             * @summary Create an currency exchange
             * @request POST:/direct-exchange
             * @secure
             */
            createDirectExchange: function (data, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/direct-exchange", method: "POST", query: query, body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Retrieve info about existing exchange operation by uuid
             *
             * @tags Operations
             * @name GetDirectExchange
             * @summary Retrieve an exchange
             * @request GET:/direct-exchange/{uuid}
             * @secure
             */
            getDirectExchange: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/direct-exchange/".concat(uuid), method: "GET", secure: true, format: "json" }, params));
            },
        };
        _this.payin = {
            /**
             * @description List of payin of current user with optional filtering by account id
             *
             * @tags Operations
             * @name GetAllPayin
             * @summary List all payin
             * @request GET:/payin
             * @secure
             */
            getAllPayin: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/payin", method: "GET", query: query, secure: true, format: "json" }, params));
            },
            /**
             * @description Create request for payin one currency on user account. `dryRun` request param allows to check resulting rate and fee amount
             *
             * @tags Operations
             * @name CreatePayin
             * @summary Create an currency payin
             * @request POST:/payin
             * @secure
             */
            createPayin: function (data, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/payin", method: "POST", query: query, body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Retrieve info about existing payin operation by uuid
             *
             * @tags Operations
             * @name GetPayin
             * @summary Retrieve an payin
             * @request GET:/payin/{uuid}
             * @secure
             */
            getPayin: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/payin/".concat(uuid), method: "GET", secure: true, format: "json" }, params));
            },
        };
        _this.balance = {
            /**
             * @description Get account balance by accountId, currency, optionally by specific date
             *
             * @tags Balance
             * @name GetBalanceList
             * @summary Get account balance
             * @request GET:/balance
             * @secure
             */
            getBalanceList: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/balance", method: "GET", query: query, secure: true, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Balance
             * @name TouchBalance
             * @summary Touch balance for currency if not exist
             * @request POST:/balance
             * @secure
             */
            touchBalance: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/balance", method: "POST", query: query, secure: true, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Balance
             * @name GetBalanceLog
             * @summary Get paged balance log
             * @request GET:/balance/log
             * @secure
             */
            getBalanceLog: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/balance/log", method: "GET", query: query, secure: true, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Balance
             * @name GetBalanceLogReceipt
             * @summary Get balance log receipt
             * @request GET:/balance/log/{logId}
             * @secure
             */
            getBalanceLogReceipt: function (logId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/balance/log/".concat(logId), method: "GET", secure: true, format: "json" }, params));
            },
        };
        _this.admin = {
            /**
             * No description
             *
             * @tags Admin
             * @name AdminListWithdraw
             * @request GET:/admin/operations/withdraw
             * @secure
             */
            adminListWithdraw: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/operations/withdraw", method: "GET", query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminCreateWithdraw
             * @summary Create a withdraw request
             * @request POST:/admin/operations/withdraw
             * @secure
             */
            adminCreateWithdraw: function (data, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/operations/withdraw", method: "POST", query: query, body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminCreateTransfer
             * @summary Create transfer between accounts
             * @request POST:/admin/operations/transfer
             * @secure
             */
            adminCreateTransfer: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/operations/transfer", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminListDirectExchange
             * @request GET:/admin/operations/exchange
             * @secure
             */
            adminListDirectExchange: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/operations/exchange", method: "GET", query: query, secure: true }, params));
            },
            /**
             * @description Create request for exchange one currency to another on user account. `dryRun` request param allows to check resulting rate and fee amount
             *
             * @tags Admin
             * @name AdminCreateDirectExchange
             * @summary Create an currency exchange
             * @request POST:/admin/operations/exchange
             * @secure
             */
            adminCreateDirectExchange: function (data, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/operations/exchange", method: "POST", query: query, body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminListAccount
             * @request GET:/admin/account
             * @secure
             */
            adminListAccount: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/account", method: "GET", query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminCreateAccount
             * @request POST:/admin/account
             * @secure
             */
            adminCreateAccount: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/account", method: "POST", query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminUnlinkAccountWithUser
             * @request POST:/admin/account/{accountId}/user/{userId}/unlink
             * @secure
             */
            adminUnlinkAccountWithUser: function (userId, accountId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/account/".concat(accountId, "/user/").concat(userId, "/unlink"), method: "POST", secure: true }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminLinkAccountWithUser
             * @request POST:/admin/account/{accountId}/user/{userId}/link
             * @secure
             */
            adminLinkAccountWithUser: function (userId, accountId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/account/".concat(accountId, "/user/").concat(userId, "/link"), method: "POST", secure: true }, params));
            },
            /**
             * @description Generic stats of wallet service
             *
             * @tags Admin
             * @name GetStats
             * @summary Generic stats
             * @request GET:/admin/stats
             * @secure
             */
            getStats: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/stats", method: "GET", secure: true }, params));
            },
            /**
             * @description Find operation by id (for example from Balance Log -> Reason Id)
             *
             * @tags Admin
             * @name FindOperation
             * @summary Get operation by id
             * @request GET:/admin/operations
             * @secure
             */
            findOperation: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/operations", method: "GET", query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminGetWithdraw
             * @summary Retrieve a withdraw
             * @request GET:/admin/operations/withdraw/{uuid}
             * @secure
             */
            adminGetWithdraw: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/operations/withdraw/".concat(uuid), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Retrieve a transfer between accounts by uuid
             *
             * @tags Admin
             * @name AdminGetTransfer
             * @summary Retrieve a transfer
             * @request GET:/admin/operations/transfer/{uuid}
             * @secure
             */
            adminGetTransfer: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/operations/transfer/".concat(uuid), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Retrieve info about existing exchange operation by uuid
             *
             * @tags Admin
             * @name AdminGetDirectExchange
             * @summary Retrieve an exchange
             * @request GET:/admin/operations/exchange/{uuid}
             * @secure
             */
            adminGetDirectExchange: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/operations/exchange/".concat(uuid), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminGetBalanceLog
             * @request GET:/admin/log
             * @secure
             */
            adminGetBalanceLog: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/log", method: "GET", query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminListDeposit
             * @request GET:/admin/deposits
             * @secure
             */
            adminListDeposit: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/deposits", method: "GET", query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminGetTotalBalance
             * @request GET:/admin/balance/totals_report
             * @secure
             */
            adminGetTotalBalance: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/balance/totals_report", method: "GET", secure: true }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminGetAccount
             * @request GET:/admin/account/{accountId}
             * @secure
             */
            adminGetAccount: function (accountId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/account/".concat(accountId), method: "GET", secure: true }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminGetAccountBalanceLog
             * @request GET:/admin/account/{accountId}/log
             * @secure
             */
            adminGetAccountBalanceLog: function (accountId, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/account/".concat(accountId, "/log"), method: "GET", query: query, secure: true }, params));
            },
            /**
             * @description Get account balance by account id, optionally by date.
             *
             * @tags Admin
             * @name AdminGetAccountBalance
             * @summary Get account balance
             * @request GET:/admin/account/{accountId}/balance
             * @secure
             */
            adminGetAccountBalance: function (accountId, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/account/".concat(accountId, "/balance"), method: "GET", query: query, secure: true }, params));
            },
            /**
             * @description Get account addresses by account id, optionally by address index, blockchain and currency.
             *
             * @tags Admin
             * @name AdminGetAddresses
             * @summary Get account addresses
             * @request GET:/admin/account/{accountId}/address
             * @secure
             */
            adminGetAddresses: function (accountId, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/account/".concat(accountId, "/address"), method: "GET", query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags Admin
             * @name AdminListAccountByUser
             * @request GET:/admin/account/user
             * @secure
             */
            adminListAccountByUser: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/account/user", method: "GET", query: query, secure: true }, params));
            },
        };
        _this.v2 = {
            /**
             * No description
             *
             * @tags Address
             * @name GetAddresses
             * @request GET:/v2/address/list
             */
            getAddresses: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/v2/address/list", method: "GET", query: query }, params));
            },
            /**
             * @description check crypto address format validity
             *
             * @tags Address
             * @name ValidateAddressFormat
             * @request GET:/v2/address/format/validation
             */
            validateAddressFormat: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/v2/address/format/validation", method: "GET", query: query }, params));
            },
            /**
             * @description Get and create address for current user
             *
             * @tags Address
             * @name GetAddress
             * @request GET:/v2/address/
             * @secure
             */
            getAddress: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/v2/address/", method: "GET", query: query, secure: true }, params));
            },
        };
        _this.utils = {
            /**
             * No description
             *
             * @tags Utils
             * @name GetLimits
             * @summary Get current withdraw total status. Currently only in calculated in EUR by the date of creation of request.
             * @request GET:/utils/limits
             * @secure
             */
            getLimits: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/utils/limits", method: "GET", secure: true }, params));
            },
        };
        _this.convert = {
            /**
             * No description
             *
             * @tags converter-controller
             * @name Convert
             * @request GET:/convert
             */
            convert: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/convert", method: "GET", query: query, format: "json" }, params));
            },
        };
        return _this;
    }
    return WalletApi;
}(HttpClient));
export { WalletApi };
