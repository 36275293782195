import React from "react";
import { observer } from "mobx-react-lite";
import { generatePath, useNavigate } from "react-router-dom";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { ROUTES } from "@/routes";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
export var NewCardButton = observer(function () {
    var navigate = useNavigate();
    var userInfo = useStoreContext(AuthStore);
    return (React.createElement(Box, { padding: "22px 20px", bgColor: "general-white", borderRadius: "22px", display: "flex", alignItems: "center", gap: "8px", sx: {
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#F6F7F8",
            },
        }, onClick: function () {
            var _a;
            if ((_a = userInfo.userInfo) === null || _a === void 0 ? void 0 : _a.userScopes.includes("cardholder_user:write")) {
                navigate(generatePath(ROUTES.orderCard));
            }
            else {
                navigate(generatePath(ROUTES.subscription));
            }
        } },
        React.createElement(Box, { width: "68px", height: "48px", bgColor: "black-100", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "6px" },
            React.createElement(BaseIcon, { icon: "Card", color: "deep-blue-500", size: 32 })),
        React.createElement(Typography, { type: "text16Bold", color: "deep-blue-500" }, "New card")));
});
