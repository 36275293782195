import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Menu } from "@/pages/UserPage/components/Menu";
import { SideMenu } from "@/pages/UserPage/components/SideMenu";
import { UserPageStore } from "@/pages/UserPage/stores/UserPageStore";
import { Box } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { MenuStore } from "@/shared/stores/MenuStore";
import { SumSubModal } from "@/features/sumsub-verification/compontents/Modal";
import { ROUTES } from "@/routes";
import { Header } from "@/pages/UserPage/components/Header";
export var UserPage = observer(function () {
    var media = useMedia();
    var location = useLocation();
    var page = useStore(UserPageStore);
    var menu = useStoreContext(MenuStore);
    var Provider = useProvider(UserPageStore);
    var isCardOrderPage = location.pathname === ROUTES.orderCard;
    return (React.createElement(Provider, { value: page },
        React.createElement(Box, { maxWidth: "1440px", margin: "0 auto" },
            !media.phone && React.createElement(Menu, null),
            React.createElement(Header, null),
            React.createElement(Container, { isCardOrderPage: isCardOrderPage },
                React.createElement(Outlet, null))),
        menu.visible && media.phone && React.createElement(SideMenu, null),
        React.createElement(SumSubModal, null)));
});
var Container = styled("div", {
    shouldForwardProp: function (prop) { return prop !== "isCardOrderPage"; },
})(function (_a) {
    var _b;
    var theme = _a.theme, isCardOrderPage = _a.isCardOrderPage;
    return (_b = {
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            minHeight: "100vh",
            width: isCardOrderPage ? "1024px" : "628px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "32px"
        },
        _b[theme.breakpoints.down("sm")] = {
            paddingLeft: 20,
            paddingRight: 20,
        },
        _b);
});
