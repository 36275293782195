import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
import timeGray from "@/shared/img/time-gray.png";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "@/routes";
export var FinalizeSubscriptionBanner = function (_a) {
    var onClose = _a.onClose;
    var navigate = useNavigate();
    return (React.createElement(Box, { width: "100%", height: "170px", borderRadius: "22px", padding: "16px", boxSizing: "border-box", bgColor: "general-orange", onClick: function () {
            navigate(generatePath(ROUTES.subscription));
        }, sx: {
            position: "relative",
            backgroundImage: "url(".concat(timeGray, ")"),
            backgroundPosition: "right bottom",
            backgroundSize: "145px",
            backgroundRepeat: "no-repeat",
            overflow: "hidden",
            padding: "20px",
            borderRadius: "16px",
        } },
        React.createElement(BaseIcon, { icon: "CloseLight", position: "absolute", top: "8px", right: "8px", style: {
                cursor: "pointer",
            }, onClick: onClose }),
        React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "flex-start", height: "100%", justifyContent: "space-between" },
            React.createElement(Box, null,
                React.createElement(Box, null,
                    React.createElement(Typography, { color: "general-white", sx: {
                            fontSize: "18px",
                            fontWeight: "bold",
                        } }, "FINALIZE YOUR SUBSCRIPTION")),
                React.createElement(Box, { marginTop: "8px" },
                    React.createElement(Typography, { color: "general-white", type: "text16", sx: { fontWeight: 400 } },
                        "Enjoy priority support and everyday spending",
                        React.createElement("br", null),
                        "protection, for less than the price of a coffee"))),
            React.createElement(Box, { padding: "12px", marginTop: "10px", borderRadius: "16px", sx: {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    cursor: "pointer",
                } },
                React.createElement(Typography, { type: "text14", color: "general-white" }, "Final Step to get Pro")))));
};
