var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, reaction, runInAction } from "mobx";
import BigNumber from "bignumber.js";
import { DataSync } from "@/shared/stores/DataStore";
import { walletApi } from "@/api/wallet/api";
import { Reactions } from "@/shared/utils/reactions";
import { parseApiError } from "@/shared/utils/error";
export var SendCryptoStep;
(function (SendCryptoStep) {
    SendCryptoStep[SendCryptoStep["ChooseCrypto"] = 0] = "ChooseCrypto";
    SendCryptoStep[SendCryptoStep["Form"] = 1] = "Form";
})(SendCryptoStep || (SendCryptoStep = {}));
var SendCryptoStore = /** @class */ (function () {
    function SendCryptoStore(accountList) {
        this.accountList = accountList;
        this.loading = false;
        this.step = SendCryptoStep.ChooseCrypto;
        this.walletAddress = "";
        this.amount = "0";
        this.submitErrors = [];
        this.result = undefined;
        this.confirmation = false;
        this.reactions = new Reactions();
        this.feeData = new DataSync(walletApi.withdraw.createWithdraw.bind(walletApi));
        makeAutoObservable(this, {}, { autoBind: true });
    }
    Object.defineProperty(SendCryptoStore.prototype, "availableBlockchains", {
        get: function () {
            var _a, _b;
            return (((_b = (_a = this.fromCurrency) === null || _a === void 0 ? void 0 : _a.tokens) === null || _b === void 0 ? void 0 : _b.map(function (token) { return token.blockchain; }).filter(function (blockchain) {
                return blockchain !== undefined;
            })) || []);
        },
        enumerable: false,
        configurable: true
    });
    SendCryptoStore.prototype.init = function () {
        var _this = this;
        this.reactions.create(reaction(function () { return [
            _this.fromCurrency,
            _this.amount,
            _this.fromBlockchain,
            _this.walletAddress,
        ]; }, function () {
            var _a;
            if (_this.fromCurrency &&
                _this.amount &&
                _this.fromBlockchain &&
                _this.walletAddress &&
                _this.accountList.checking) {
                _this.feeData.sync({
                    amount: _this.amount,
                    currency: (_a = _this.fromCurrency) === null || _a === void 0 ? void 0 : _a.slug,
                    type: "CRYPTO",
                    blockchain: _this.fromBlockchain.slug,
                    fromAccount: _this.accountList.checking.account,
                    to: _this.walletAddress,
                }, {
                    autoConversion: true,
                    dryRun: true,
                });
            }
            else {
                _this.feeData.reset();
            }
        }, {
            fireImmediately: true,
        }));
    };
    SendCryptoStore.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var errors, result, resp, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        errors = [];
                        runInAction(function () {
                            _this.loading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (!this.feeData.value) {
                            throw new Error("feeData.value must be defined");
                        }
                        return [4 /*yield*/, walletApi.withdraw.createWithdraw(this.feeData.value.data, {
                                autoConversion: true,
                                dryRun: false,
                            })];
                    case 2:
                        resp = _a.sent();
                        result = resp.data;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        errors = parseApiError(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        runInAction(function () {
                            _this.loading = false;
                            _this.submitErrors = errors;
                            _this.result = result;
                            _this.confirmation = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(SendCryptoStore.prototype, "fee", {
        get: function () {
            var _a;
            return (_a = this.feeData.value) === null || _a === void 0 ? void 0 : _a.data.feeAmount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SendCryptoStore.prototype, "total", {
        get: function () {
            var _a;
            return ((_a = this.feeData.value) === null || _a === void 0 ? void 0 : _a.data.feeAmount)
                ? new BigNumber(this.feeData.value.data.amount)
                    .plus(this.feeData.value.data.feeAmount)
                    .toFixed()
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SendCryptoStore.prototype, "ready", {
        get: function () {
            return this.feeData.value && !this.feeData.loading && this.valid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SendCryptoStore.prototype, "valid", {
        get: function () {
            return (this.walletAddress &&
                this.amount &&
                new BigNumber(this.amount).gt(0) &&
                !new BigNumber(this.amount).isNaN());
        },
        enumerable: false,
        configurable: true
    });
    SendCryptoStore.prototype.reset = function () {
        this.loading = false;
        this.amount = "0";
        this.submitErrors = [];
        this.result = undefined;
        this.confirmation = false;
        this.feeData.reset();
    };
    SendCryptoStore.prototype.showConfirmation = function () {
        this.confirmation = true;
        this.submitErrors = [];
    };
    SendCryptoStore.prototype.hideConfirmation = function () {
        this.confirmation = false;
    };
    SendCryptoStore.prototype.hideErrors = function () {
        this.submitErrors = [];
    };
    return SendCryptoStore;
}());
export { SendCryptoStore };
