import React from "react";
import { CircularProgress } from "@mui/material";
import BigNumber from "bignumber.js";
import { observer } from "mobx-react-lite";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { Amount } from "@/shared/components/Amount";
import { useToggler } from "@/shared/hooks/useToggler";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { ExchangeType } from "@/routes";
import { CardListStore } from "@/shared/stores/CardListStore";
import { CardIcon } from "@/shared/components/CardIcon";
var PaymentType;
(function (PaymentType) {
    PaymentType[PaymentType["CRYPTO"] = 0] = "CRYPTO";
    PaymentType[PaymentType["CARDS"] = 1] = "CARDS";
    PaymentType[PaymentType["ACCOUNTS"] = 2] = "ACCOUNTS";
})(PaymentType || (PaymentType = {}));
export var TokenAmountInput = observer(function (_a) {
    var _b;
    var amount = _a.amount, currency = _a.currency, balances = _a.balances, onCurrencyChange = _a.onCurrencyChange, onAmountChange = _a.onAmountChange, type = _a.type, onSetAll = _a.onSetAll, card = _a.card, onPayInClick = _a.onPayInClick, showPayIn = _a.showPayIn, showCards = _a.showCards;
    var selectedCurrencyDropDown = useToggler();
    var currencyList = useStoreContext(CurrencyListStore);
    var currencies = currencyList.withWalletEnabled.filter(function (c) { return c.type === "CRYPTO"; });
    var cardList = useStoreContext(CardListStore);
    var balance = currency.slug ? balances[currency.slug] : undefined;
    var _c = React.useState(PaymentType.CRYPTO), paymentType = _c[0], setPaymentType = _c[1];
    var operationSymbol = type === ExchangeType.Buy
        ? "+"
        : type === ExchangeType.Sell
            ? "-"
            : "";
    var handleAmountChange = function (event) {
        var value = event.target.value.replace(operationSymbol, "");
        onAmountChange(value);
    };
    var enoughFunds = balance
        ? type === ExchangeType.Sell
            ? new BigNumber(amount).lte(balance.balance)
            : true
        : undefined;
    var changePaymentType = function (type) {
        setPaymentType(type);
    };
    var renderPaymentTypeList = function (paymentType) {
        switch (paymentType) {
            case PaymentType.CRYPTO:
                return currencies.map(function (currency) {
                    var _a, _b, _c;
                    return (React.createElement(Box, { display: "flex", alignItems: "center", borderRadius: "12px", sx: {
                            "&:hover": {
                                backgroundColor: "#F6F7F8",
                            },
                            cursor: "pointer",
                        }, justifyContent: "space-between", padding: "8px 20px", onClick: function () {
                            onCurrencyChange(currency.slug);
                            selectedCurrencyDropDown.off();
                        } },
                        React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                            React.createElement(TokenIcon, { symbol: currency.slug, iconUrl: currency.iconUrl, size: 36 }),
                            React.createElement(Box, { display: "flex", alignItems: "center", gap: "6px" },
                                React.createElement(Typography, { type: "text16" }, currency.shortName),
                                React.createElement(Typography, { type: "text16", color: "black-500" }, currency.description))),
                        currency.slug && (React.createElement(Box, { display: "flex", flexDirection: "column" },
                            React.createElement(Typography, { type: "text14", textAlign: "end", color: enoughFunds
                                    ? "black-500"
                                    : "general-red" }, (_a = currency.shortName) === null || _a === void 0 ? void 0 :
                                _a.toUpperCase(),
                                " ",
                                React.createElement(Amount, { from: currency.slug, to: currency.slug, amount: (_b = balances[currency.slug]) === null || _b === void 0 ? void 0 : _b.balance })),
                            React.createElement(Typography, { type: "text12", color: "black-500", textAlign: "end" },
                                React.createElement(Amount, { from: currency.slug, amount: (_c = balances[currency.slug]) === null || _c === void 0 ? void 0 : _c.balance }))))));
                });
            case PaymentType.CARDS:
                return (React.createElement(React.Fragment, null,
                    showCards &&
                        cardList.list.map(function (card) {
                            var _a;
                            return (React.createElement(Box, { display: "flex", alignItems: "center", borderRadius: "12px", sx: {
                                    "&:hover": {
                                        backgroundColor: "#F6F7F8",
                                    },
                                    cursor: "pointer",
                                }, justifyContent: "space-between", padding: "8px 20px", onClick: function () {
                                    onCurrencyChange(card.currency.toLowerCase(), card.id);
                                    selectedCurrencyDropDown.off();
                                } },
                                React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                                    React.createElement(CardIcon, { lastFour: card.last4, size: 48, color: (_a = card.cardDesign) === null || _a === void 0 ? void 0 : _a.description }),
                                    React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "center" },
                                        React.createElement(Typography, { type: "text12" },
                                            "\u2022\u2022 ",
                                            card.last4),
                                        React.createElement(Typography, { type: "text12", color: "black-500" }, card.cardName))),
                                React.createElement(Box, null,
                                    React.createElement(Typography, { type: "text12", textAlign: "end" },
                                        React.createElement(Amount, { from: card.currency.toLowerCase(), amount: card.balance })))));
                        }),
                    showPayIn && (React.createElement(Box, { display: "flex", alignItems: "center", borderRadius: "12px", sx: {
                            "&:hover": {
                                backgroundColor: "#F6F7F8",
                            },
                            cursor: "pointer",
                        }, justifyContent: "space-between", padding: "0px 20px", onClick: onPayInClick },
                        React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center" },
                            React.createElement(BaseIcon, { icon: "VisaMastercard", size: 62 }),
                            React.createElement(Typography, { type: "text14", color: "deep-blue-500" }, "From external card"))))));
            default:
                return null;
        }
    };
    return (React.createElement(Box, { sx: {
            border: enoughFunds
                ? "1px solid #DDDFE4"
                : "1px solid #FF6464",
        }, borderRadius: "16px", padding: "12px 16px", display: "flex", justifyContent: "space-between", height: "66px", boxSizing: "border-box", alignItems: "center", position: "relative" },
        card ? (React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center" },
            React.createElement(CardIcon, { lastFour: card.last4, size: 44, color: (_b = card.cardDesign) === null || _b === void 0 ? void 0 : _b.description }),
            React.createElement(Box, null,
                React.createElement(Box, { display: "flex", gap: "8px", alignItems: "center", sx: { cursor: "pointer" }, onClick: selectedCurrencyDropDown.enabled
                        ? selectedCurrencyDropDown.off
                        : selectedCurrencyDropDown.on },
                    React.createElement(Box, { display: "flex", gap: "4px", alignItems: "center" },
                        React.createElement(Typography, { type: "text16" }, card.currency)),
                    React.createElement(BaseIcon, { icon: "DropDown" })),
                React.createElement(Box, null,
                    React.createElement(Typography, { type: "text12", color: enoughFunds
                            ? "black-500"
                            : "general-red" },
                        "Balance:",
                        " ",
                        React.createElement(Amount, { from: card.currency.toLowerCase(), to: card.currency.toLowerCase(), amount: card.balance })))))) : (React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center" },
            React.createElement(TokenIcon, { symbol: currency.slug, size: 44, iconUrl: currency.iconUrl }),
            React.createElement(Box, null,
                React.createElement(Box, { display: "flex", gap: "8px", alignItems: "center", sx: { cursor: "pointer" }, onClick: selectedCurrencyDropDown.enabled
                        ? selectedCurrencyDropDown.off
                        : selectedCurrencyDropDown.on },
                    React.createElement(Box, { display: "flex", gap: "4px", alignItems: "center" },
                        React.createElement(Typography, { type: "text16" }, currency.shortName)),
                    React.createElement(BaseIcon, { icon: "DropDown" })),
                React.createElement(Box, null, (balance === null || balance === void 0 ? void 0 : balance.balance) ? (React.createElement(Typography, { type: "text12", color: enoughFunds
                        ? "black-500"
                        : "general-red" },
                    "Balance:",
                    " ",
                    React.createElement(Amount, { from: currency.slug, to: currency.slug, amount: balance === null || balance === void 0 ? void 0 : balance.balance }))) : (React.createElement(CircularProgress, { size: 8 })))))),
        React.createElement(Box, { height: "100%", display: "flex", flexDirection: "column", alignItems: "flex-end" },
            React.createElement(Box, { display: "flex", gap: "8px" },
                React.createElement("input", { value: "".concat(operationSymbol).concat(amount), onChange: handleAmountChange, style: {
                        border: "none",
                        textAlign: "end",
                        color: enoughFunds ? "#80889C" : "#FF6464",
                        outline: "none",
                        boxShadow: "none",
                    } }),
                type === ExchangeType.Sell && (React.createElement(Typography, { color: "deep-blue-500", textDecoration: "underline", sx: {
                        cursor: "pointer",
                    }, onClick: onSetAll }, "All"))),
            !enoughFunds && (React.createElement(Typography, { type: "text14", color: "general-red" }, "Not enough funds"))),
        selectedCurrencyDropDown.enabled && (React.createElement(Box, { position: "absolute", top: "calc(100% + 14px)", left: "0px", width: "100%", maxHeight: "528px", overflow: "scroll", padding: "12px", zIndex: 1000, bgColor: "general-white", borderRadius: "16px", boxSizing: "border-box", sx: {
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
            } },
            React.createElement(Box, { borderRadius: "20px", width: "100%", padding: "8px", bgColor: "black-50", display: "flex", gap: "4px" },
                React.createElement(Box, { borderRadius: "20px", display: "flex", justifyContent: "center", alignItems: "center", height: "32px", flexGrow: 1, sx: {
                        cursor: "pointer",
                    }, bgColor: paymentType === PaymentType.CRYPTO
                        ? "general-white"
                        : "black-50", onClick: function () {
                        changePaymentType(PaymentType.CRYPTO);
                    } },
                    React.createElement(Typography, { type: "text14", color: paymentType === PaymentType.CRYPTO
                            ? "black-1200"
                            : "black-500" }, "Crypto")),
                React.createElement(Box, { borderRadius: "20px", display: "flex", justifyContent: "center", alignItems: "center", height: "32px", flexGrow: 1, sx: {
                        cursor: "pointer",
                    }, bgColor: paymentType === PaymentType.CARDS
                        ? "general-white"
                        : "black-50", onClick: function () {
                        changePaymentType(PaymentType.CARDS);
                    } },
                    React.createElement(Typography, { type: "text14", color: paymentType === PaymentType.CARDS
                            ? "black-1200"
                            : "black-500" }, "Cards"))),
            React.createElement(Box, { marginTop: "6px" }, renderPaymentTypeList(paymentType))))));
});
