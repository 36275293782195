import * as React from "react";
import { styled } from "@mui/material/styles";
import { VerificationBanner } from "@/entities/Verification";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { TotalBalance } from "@/pages/HomePage/components/TotalBalance";
import { BalanceType } from "@/shared/stores/BalanceTypeStore";
export var VerificationLayout = function (_a) {
    var withTotalBalance = _a.withTotalBalance;
    return (React.createElement(React.Fragment, null,
        React.createElement(VerificationBanner, null),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px", position: "relative", width: "100%" },
            withTotalBalance && React.createElement(TotalBalance, { type: BalanceType.Crypto }),
            React.createElement(StyledOverlay, { position: "absolute", top: "0", left: "0", width: "100%", height: "100%" })),
        React.createElement(Box, { padding: "30px", display: "flex", flexDirection: "column", bgColor: "general-white", borderRadius: "22px", alignItems: "center", justifyContent: "center", mb: "40px" },
            React.createElement(BaseIcon, { size: 24, icon: "Lock" }),
            React.createElement(Typography, { color: "black-500", textAlign: "center", maxWidth: "440px" }, "Unlock full access to our crypto and banking services by completing the identity verification process."))));
};
var StyledOverlay = styled(Box)(function () { return ({
    background: "linear-gradient(180deg, rgba(246, 247, 248, 0.4) 0%, #F6F7F8 100%)",
}); });
