import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "@/routes";
import { Box, Typography } from "@/shared/components";
import { Amount } from "@/shared/components/Amount";
import { Button } from "@/shared/components/Button";
import { Link } from "@/shared/components/Link";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { useMedia } from "@/shared/hooks/useMedia";
import { generateQueryPath } from "@/shared/utils/path";
export var BalanceItem = function (_a) {
    var currency = _a.currency, description = _a.description, slug = _a.slug, id = _a.id, amount = _a.amount, type = _a.type, withAddFunds = _a.withAddFunds, iconUrl = _a.iconUrl;
    var phone = useMedia().phone;
    var navigate = useNavigate();
    return (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between", gap: "8px", onClick: function () {
            navigate(generatePath(ROUTES.walletItem, {
                currency: slug,
            }));
        }, sx: { cursor: "pointer" } },
        React.createElement(Link, { to: generatePath(ROUTES.walletItem, { currency: currency }) },
            React.createElement(TokenIcon, { symbol: currency, iconUrl: iconUrl, size: 48 })),
        React.createElement(Box, { display: "flex", flexDirection: "column", flexGrow: "1" },
            React.createElement(Box, { display: "flex", alignItems: "center", gap: "4px" },
                React.createElement(Typography, { type: "text14" }, currency),
                description && (React.createElement(Box, { padding: "3px", bgColor: "black-100", sx: {
                        fontSize: "10px",
                        color: "#454A58",
                    }, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "3px" }, description))),
            React.createElement(Typography, { type: phone ? "text12" : "text14", color: "black-400" },
                React.createElement(Amount, { from: slug, amount: "1" }))),
        React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "center" },
            React.createElement(Typography, { textAlign: "end", sx: {
                    fontSize: "14px",
                    lineHeight: "120%",
                } },
                React.createElement(Amount, { from: slug, amount: amount || "0" })),
            React.createElement(Typography, { textAlign: "end", type: "text12", color: "black-500" },
                React.createElement(Amount, { from: slug, to: slug, amount: amount || "0", withSymbol: false }))),
        withAddFunds && (React.createElement(Button, { width: "auto", variant: "Bordered", size: "s", LinkComponent: Link, to: generateQueryPath(ROUTES.deposit, {
                type: type,
                id: id,
            }, {
                currency: slug,
            }) }, "Add funds")),
        " "));
};
