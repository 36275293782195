import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@/shared/components";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CardOrderStore } from "@/pages/CardOrderPage/stores/CardOrderStore";
import { CardIcon } from "@/shared/components/CardIcon";
export var PickCardColor = observer(function (_a) {
    var designs = _a.designs;
    if (!designs) {
        return null;
    }
    var cardOrder = useStoreContext(CardOrderStore);
    useEffect(function () {
        if (designs.length > 0 && !cardOrder.cardDesignId) {
            cardOrder.setCardDesignId(designs[0].id);
        }
    }, [designs, cardOrder]);
    var handleColorChange = function (id) {
        cardOrder.setCardDesignId(id);
    };
    return (React.createElement(Box, null,
        React.createElement(Typography, { type: "text20" }, "Pick your color"),
        React.createElement(ColorPickerContainer, null, designs.map(function (design) { return (React.createElement(Box, { key: design.id, onClick: function () { return handleColorChange(design.id); }, borderColor: cardOrder.cardDesignId === design.id
                ? "deep-blue-500"
                : "black-100", borderWidth: "2px", borderRadius: "22px", padding: "8px", borderStyle: "solid" },
            React.createElement(CardIcon, { size: 76, color: design.description }))); }))));
});
var ColorPickerContainer = styled(Box)({
    display: "flex",
    gap: "16px",
    marginTop: "24px",
});
