var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, runInAction } from "mobx";
import { loadCheckoutWebComponents } from "@checkout.com/checkout-web-components";
import { walletApi } from "@/api/wallet/api";
import { acquiringApi } from "@/api/acquiring";
import { InvoicePaymentProviderType, } from "@/api/acquiring/types";
var ExchangeStore = /** @class */ (function () {
    function ExchangeStore() {
        this.accountId = undefined;
        this.fromAmount = "0";
        this.toAmount = "0";
        this.fromAllAmount = undefined;
        this.toAllAmount = undefined;
        this.fromCurrency = undefined;
        this.toCurrency = undefined;
        this.balance = undefined;
        this.fromCard = undefined;
        this.toCard = undefined;
        this.feePercent = undefined;
        this.invoiceId = undefined;
        this.session = undefined;
        this.payInSuccess = false;
        this.errors = [];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    ExchangeStore.prototype.reset = function () {
        var _this = this;
        runInAction(function () {
            _this.fromAmount = "0";
            _this.toAmount = "0";
            _this.payInSuccess = false;
        });
    };
    ExchangeStore.prototype.createPayIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var createPayInResponse, data, invoiceId_1, e_1;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, walletApi.payin.createPayin({
                                account: this.accountId,
                                toCurrency: (_a = this.fromCurrency) === null || _a === void 0 ? void 0 : _a.slug,
                                fromCurrency: "usd",
                                fromAmount: this.fromAmount,
                            })];
                    case 1:
                        createPayInResponse = _b.sent();
                        data = createPayInResponse.data;
                        invoiceId_1 = data.invoiceId;
                        runInAction(function () {
                            _this.invoiceId = invoiceId_1;
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        console.error(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ExchangeStore.prototype.createPayment = function () {
        return __awaiter(this, void 0, void 0, function () {
            var createPaymentResponse, data, session, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!this.invoiceId) return [3 /*break*/, 2];
                        return [4 /*yield*/, acquiringApi.invoice.paymentGetOrCreate({
                                invoiceId: this.invoiceId,
                                type: InvoicePaymentProviderType.CHECKOUT,
                            })];
                    case 1:
                        createPaymentResponse = _a.sent();
                        data = createPaymentResponse.data;
                        session = data.session;
                        this.session = session;
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ExchangeStore.prototype.createCheckoutForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var paymentSession, checkout, flow, e_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!this.session) return [3 /*break*/, 2];
                        paymentSession = JSON.parse(this.session.body);
                        return [4 /*yield*/, loadCheckoutWebComponents({
                                publicKey: "pk_sbox_v2o23llyd4i26l3uszk77b5no43",
                                environment: "sandbox",
                                locale: "en-GB",
                                paymentSession: paymentSession,
                                onPaymentCompleted: function (_component, paymentResponse) {
                                    console.log("Create Payment with PaymentId: ", paymentResponse);
                                    runInAction(function () {
                                        _this.payInSuccess = true;
                                    });
                                },
                                onError: function (_component, error) {
                                    var err = JSON.parse(JSON.stringify(error));
                                    console.error(err);
                                    runInAction(function () {
                                        _this.errors = [
                                            {
                                                key: "0",
                                                description: err === null || err === void 0 ? void 0 : err.code,
                                            },
                                        ];
                                    });
                                },
                            })];
                    case 1:
                        checkout = _a.sent();
                        flow = checkout.create("flow");
                        flow.mount("#flow-container");
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ExchangeStore.prototype.checkout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.accountId) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.createPayIn()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.createPayment()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.createCheckoutForm()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return ExchangeStore;
}());
export { ExchangeStore };
