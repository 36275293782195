import { formattedTokenAmount } from "@broxus/js-utils";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import { ExchangeType, ROUTES } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { Amount } from "@/shared/components/Amount";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { useBack } from "@/shared/hooks/useBack";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
export var WalletPage = observer(function () {
    var _a, _b, _c, _d;
    var params = useParams();
    var back = useBack();
    var accountList = useStoreContext(AccountListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var addressList = useStoreContext(AddressListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var account = accountList.checking;
    var currencySlug = params.currency;
    var selectedCurrency = currencyList.list.find(function (c) { return c.slug === currencySlug; });
    var balance = (_b = (currencySlug && ((_a = balanceList.data[currencySlug]) === null || _a === void 0 ? void 0 : _a.balance))) !== null && _b !== void 0 ? _b : "0";
    React.useEffect(function () {
        addressList.sync(account);
    }, [account, addressList]);
    React.useEffect(function () {
        if (!account) {
            accountList.sync();
        }
        else {
            balanceList.sync(account.account);
        }
    }, [account, params.id, balanceList, accountList]);
    return (React.createElement(Box, null,
        React.createElement(Box, { display: "flex", gap: "8px" },
            React.createElement(BaseIcon, { icon: "Back", onClick: back, size: 24 }),
            React.createElement(TokenIcon, { symbol: selectedCurrency === null || selectedCurrency === void 0 ? void 0 : selectedCurrency.slug, iconUrl: selectedCurrency === null || selectedCurrency === void 0 ? void 0 : selectedCurrency.iconUrl, size: 32 }),
            React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Typography, { type: "text24" }, (_c = selectedCurrency === null || selectedCurrency === void 0 ? void 0 : selectedCurrency.shortName) === null || _c === void 0 ? void 0 :
                    _c.toUpperCase(),
                    " \u2022",
                    " ", selectedCurrency === null || selectedCurrency === void 0 ? void 0 :
                    selectedCurrency.description),
                React.createElement(Typography, { type: "text18" },
                    React.createElement(Amount, { from: selectedCurrency === null || selectedCurrency === void 0 ? void 0 : selectedCurrency.slug, amount: "1" })))),
        React.createElement(Box, { display: "flex", gap: "4px", flexDirection: "column", borderRadius: "24px", bgColor: "general-white", padding: "24px", width: "100%", marginTop: "24px" },
            React.createElement(Typography, { type: "text16", color: "black-500" }, "Holding"),
            React.createElement(Typography, { type: "text28" },
                React.createElement(Amount, { from: selectedCurrency === null || selectedCurrency === void 0 ? void 0 : selectedCurrency.slug, amount: balance })),
            React.createElement(Typography, { type: "text16", color: "black-500" }, (_d = selectedCurrency === null || selectedCurrency === void 0 ? void 0 : selectedCurrency.slug) === null || _d === void 0 ? void 0 :
                _d.toUpperCase(),
                " ",
                formattedTokenAmount(balance))),
        React.createElement(Box, { display: "flex", gap: "16px", marginTop: "16px" },
            React.createElement(Button, { width: "100px", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                    type: ExchangeType.Buy,
                }, {
                    from: selectedCurrency === null || selectedCurrency === void 0 ? void 0 : selectedCurrency.slug,
                }) }, "Buy"),
            React.createElement(Button, { width: "100px", variant: "LightBlue", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                    type: ExchangeType.Sell,
                }, {
                    from: selectedCurrency === null || selectedCurrency === void 0 ? void 0 : selectedCurrency.slug,
                }) }, "Sell"),
            React.createElement(Button, { width: "100px", variant: "LightBlue", LinkComponent: Link, to: generatePath(ROUTES.sendCrypto) }, "Send"),
            React.createElement(Button, { width: "100px", variant: "LightBlue", LinkComponent: Link, to: generatePath(ROUTES.receiveCrypto) }, "Receive"))));
});
