import React from "react";
import { Popup } from "@/shared/components/Popup";
import { BaseIcon, Box } from "@/shared/components";
export var PayInModal = function (_a) {
    var onClose = _a.onClose;
    return (React.createElement(Popup, null,
        React.createElement(Box, { display: "flex", justifyContent: "space-between", padding: "8px" },
            React.createElement(Box, null),
            React.createElement(BaseIcon, { icon: "Close", size: 36, style: {
                    cursor: "pointer",
                }, onClick: onClose })),
        React.createElement("div", { id: "flow-container" })));
};
